import React, { useState } from "react";
import "./StoryApproval.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ApprovalGallery from "./ApprovalGallery";

/*
Stories are retrieved in Gallery

*/

const textVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0 },
};

const imageVariant = {
  visible: { opacity: 0.2, transition: { duration: 3 } },
  hidden: { opacity: 1 },
};

const AnimatedImage = ({ imgRef }) => {
  const [reference, inView] = useInView();

  return (
    <motion.div
      ref={reference}
      variants={imageVariant}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}>
      <img src={imgRef} alt="Image" />
    </motion.div>
  );
};

const AnimatedText = ({ text }) => {
  const [reference, inView] = useInView();

  return (
    <motion.div
      ref={reference}
      variants={textVariant}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}>
      <p>{text}</p>
    </motion.div>
  );
};

const PasswordProtectedPage = ({ setPasswordCorrect }) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Check if the password is correct
    const correctPassword = "applepie";
    if (password === correctPassword) {
      setPasswordCorrect(true);
    }
  };

  return (
    /* password protected page is not yet fully styled - mostly is a skeleton*/
    <div className="passwordPage">
      <form onSubmit={handleFormSubmit} className="passwordForm">
        <p className="password-hero">Story Approval</p>
        <p className="password-text">
          Please enter the admin password to approve stories
        </p>
        <br></br>
        <label htmlFor="password">Enter Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          className="password-input"
        />
        <button type="submit" className="password-submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default function StoryApproval() {
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  // password protection
  if (!passwordCorrect) {
    return <PasswordProtectedPage setPasswordCorrect={setPasswordCorrect} />;
  }

  return (
    <div className="storyApproveContainer">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        <div style={{ marginLeft: "2%", paddingTop: "15%" }}>
          <div className="digital-story-gallery-title">Story Approval</div>
        </div>
        <div className="imgList">
          <ul className="clearfix">
            <ApprovalGallery />
          </ul>
        </div>
      </motion.div>
    </div>
  );
}
