// Yoinked from https://regexr.com/4lrm3

const VIMEO_LINK_REGEX =
  /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/|channels\/staffpicks\/)?(\d*)/;

/**
 * Extracts the Vimeo ID from the given link
 * @param {string} link Vimeo link
 */
export function extractVimeoId(link) {
  var m = link.match(VIMEO_LINK_REGEX);
  if (m === null) {
    // Could not extract an ID
    return null;
  } else {
    // ID is in group 1
    // console.log(m[1]);
    return m[1];
  }
}
