import React from "react";
import ReactDOM from "react-dom/client";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import "./index.css";

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { BrowserRouter as Router } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyC_VspbbcWIqOD6JBnb7_XvAE0ffXt3k6a",
  authDomain: "creative-aging.firebaseapp.com",
  projectId: "creative-aging",
  storageBucket: "creative-aging.appspot.com",
  messagingSenderId: "427605582324",
  appId: "1:427605582324:web:2c0946e9f6689ebac0d9a1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const db = getFirestore(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);
