import React, { useState, useRef, useEffect, useCallback } from "react";
import HiddenFileUpload from "./HiddenFileUpload";
import Modal from "./Modal";
import styles from "./UploadMedia.module.css";
import youtubeIcon from "../Images/youtube-icon.png";
import vimeoIcon from "../Images/vimeo-icon.png";
import soundcloudIcon from "../Images/soundcloud-icon.png";
import { extractYoutubeId } from "../../utils/youtube-id";
import { extractVimeoId } from "../../utils/vimeo-id";
import { extractSoundcloudId } from "../../utils/soundcloud-id";

/**
 * Modal for entering YouTube link
 * @param {{show: boolean, onHide(): void, setLink(link: string): void}} props Enter YouTube
 * link panel props
 * @returns Enter YouTube link modal component
 */

export default function UploadMedia({ setMedia, width, height }) {
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const [showVimeoModal, setShowVimeoModal] = useState(false);
  const [showSoundcloudModal, setShowSoundcloudModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const hiddenFileInput = useRef(null);

  const setFile = useCallback(
    (file) =>
      setMedia({
        type: "upload",
        file,
      }),
    [setMedia]
  );

  const handleHideModal = () => {
    setShowYoutubeModal(false);
    setShowVimeoModal(false);
    setShowSoundcloudModal(false);
    setErrorMessage("");
  };

  const setYoutubeLink = useCallback(
    (link) => {
      const embedId = extractYoutubeId(link);
      if (embedId === null) {
        //alert("Invalid YouTube link");
        setErrorMessage("Invalid YouTube link");
      } else {
        setMedia({
          type: "youtube",
          embedId,
        });
        handleHideModal(); // Only hide modal if link is valid
      }
    },
    [setMedia]
  );

  const setVimeoLink = useCallback(
    (link) => {
      const embedId = extractVimeoId(link);
      if (embedId === null) {
        //alert("Invalid Vimeo link");
        setErrorMessage("Invalid Vimeo link");
      } else {
        setMedia({
          type: "vimeo",
          embedId,
        });
        handleHideModal(); // Only hide modal if link is valid
      }
    },
    [setMedia]
  );

  const setSoundcloudLink = useCallback(
    (link) => {
      const embedId = extractSoundcloudId(link);

      if (embedId === null) {
        //alert("Invalid SoundCloud link");
        setErrorMessage("Invalid SoundCloud link");
      } else {
        setMedia({
          type: "soundcloud",
          embedId,
        });
        handleHideModal(); // Only hide modal if link is valid
      }
    },
    [setMedia]
  );

  return (
    <>
      <Modal
        show={showYoutubeModal}
        onHide={() => {
          setErrorMessage("");
          setShowYoutubeModal(false);
        }}
        setLink={setYoutubeLink}>
        Enter YouTube Link
        <div style={{ color: "red" }}>{errorMessage}</div>
      </Modal>
      <Modal
        show={showVimeoModal}
        onHide={() => {
          setShowVimeoModal(false);
          setErrorMessage("");
        }}
        setLink={setVimeoLink}>
        Enter Vimeo Link
        <div style={{ color: "red" }}>{errorMessage}</div>
      </Modal>
      <Modal
        show={showSoundcloudModal}
        onHide={() => {
          setShowSoundcloudModal(false);
          setErrorMessage("");
        }}
        setLink={setSoundcloudLink}>
        Enter SoundCloud Link
        <div style={{ color: "red" }}>{errorMessage}</div>
      </Modal>
      <div
        className={styles.uploadMediaBox}
        style={{ width: width, height: height }}>
        <div className={styles.half}>
          <HiddenFileUpload
            ref={hiddenFileInput}
            imageOnly={true}
            onChange={setFile}
          />
          <button
            className={styles.fileUpload}
            onClick={() => hiddenFileInput.current.click()}>
            <i
              className="fa-solid fa-cloud-arrow-up"
              style={{ fontSize: "1.0em" }}></i>
            &nbsp;Upload Image
          </button>
        </div>

        <div className={styles.orDivision}>
          <div className={styles.strike}>
            <span style={{ fontSize: "14pt" }}>OR</span>
          </div>
        </div>

        <div className={styles.half}>
          <div className={styles.youtubeInputContainer}>
            <input
              type="image"
              className={styles.uploadIcon}
              src={youtubeIcon}
              alt="YouTube link upload"
              onClick={() => setShowYoutubeModal(true)}
            />
            &nbsp;
            <input
              type="image"
              className={styles.uploadIcon}
              src={vimeoIcon}
              alt="Vimeo link upload"
              onClick={() => setShowVimeoModal(true)}
            />
            &nbsp;
            <input
              type="image"
              className={styles.uploadIcon}
              src={soundcloudIcon}
              alt="SoundCloud link upload"
              onClick={() => setShowSoundcloudModal(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
