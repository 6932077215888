import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./Story.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, 
        doc,
        getDoc,
        query,
        collection,
        orderBy,
        getDocs,
        where,
     } from "firebase/firestore";
import { motion } from "framer-motion";
import LoadingIcons from "react-loading-icons";
import YoutubeEmbed from "../UploadUtilities/YoutubeEmbed";
import Vimeo from "@u-wave/react-vimeo";
import ReactSoundCloud from "react-soundcloud-embedded";
import StoryPanel from "./StoryPanel";

function Story() {
  // searchKey: base64 representation of the email + name + story title to search for in Firebase
  const { title: searchKey } = useParams();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log("Reached the bottom!");
        // You can perform actions here when bottom is reached
      }
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [loaded, setLoaded] = useState(false);
  const [panels, setPanels] = useState([]);
  // TODO: Do we want to display the Story Name somewhere?
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [background, setBackground] = useState("");
  const [bgLoaded, setBgLoaded] = useState(false);
  const [previousStory, setPreviousStory] = useState("");
  const [nextStory, setNextStory] = useState("");
  const [docs, setDocs] = useState([]);
  const[date, setDate] = useState("");

  const generatePanelJSX = useCallback(
    // to be frank, I am not sure how the params are passed to this function and at this point I am scared to
    // look into it. lol

    /**
     * Generates the component for the panel
     * @param {object} panel Panel data
     * @param {number} index Index of this panel
     * // option looks like it is declared in panel.PanelType
     * @returns React component for the panel
     */

    // fixing this function holds the key to fixing the panels though. no cappywappy
    (panel, index) => {
      // Select the correct panel component
      // Note: this works because all six take the same props

      return (
        <StoryPanel
          key={`panel${panel.id}`}
          data={panel}
          panelOption={panel.PanelType}
        />
      );
    }
  );

  // Initiating Firebase
  const db = getFirestore();

 

  // Retreiving data from Firebase
  const navigate = useNavigate();
  const getData = useCallback(async () => {
    const docRef = doc(db, "stories", searchKey);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const info = docSnap.data();
      setName(info.name);
      setTitle(info.title);
      setBackground(info.backgroundSelected);
      setDate(info.date.toDate().toDateString());
      setPanels(info.panels.map(generatePanelJSX));
    } else {
      // Story not found; navigate back to the Stories page
      alert("Story does not exist");
      navigate("/stories");
    }
  }, [setBackground, setPanels, db, navigate, searchKey]);
 
  const getStoryList = useCallback(async () => {
    // Query the collection of stories
    const collections = query(
      collection(db, "stories"),
      orderBy("date", "desc")
    );
    const data = await getDocs(collections);
    const processedDocs = data.docs.filter(doc => doc.data().approved == true)
    .map(doc => {
      let docData = doc.data();
      docData.id = doc.id; // Add document ID to the data object
      return docData;
    });
    setDocs(processedDocs);

    // Find the index of the current story
    const index = processedDocs.findIndex(doc => doc.id === searchKey);
  
    if (index > 0) {
      setPreviousStory("/Story/" + processedDocs[index - 1].id);
    } else {
      setPreviousStory("/Stories");
    }
    if (index < processedDocs.length - 1) {
      setNextStory( "/Story/" + processedDocs[index + 1].id); 
    } else {
      setNextStory("/Stories");
    }

    
    
  }, [db]);

  useEffect(() => {
    // Load the panels of the story
    getData().then(() => {
      // Set status once complete, to display the panels
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    // Load the panels of the story
 
    if (docs.length == 0) {
    getStoryList().then(() => {
      console.log("Story list loaded");
       });
      }
});

  useEffect(() => {
    if (background != "") {
      var bgImg = new Image();
      bgImg.onload = function () {
        setBgLoaded(true);
      };
      bgImg.src = background;
    }
  }, [background]);


  const storyRef = useRef(null);
  const arrowRef = useRef(null);
  const closeButton = useRef(null);
  const xbutton = useRef(null);

 
  const handleScroll = () => {
    const newOpacity = Math.max(0.8 - window.scrollY / 300, 0);
    if (storyRef.current) {
      storyRef.current.style.opacity = newOpacity;
    }
    if (arrowRef.current) {
      arrowRef.current.style.opacity = newOpacity;
    }
    if (closeButton.current) {
      if (newOpacity >= 0.2){
      xbutton.current.classList.remove("text-black")
      xbutton.current.classList.add("text-white")
     
    }else{
      xbutton.current.classList.add("text-black")
      xbutton.current.classList.remove("text-white")
    }
    
    }
  };

 

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!(loaded && bgLoaded)) {
    return (
      <div className={styles.loading}>
        Loading story...
        <LoadingIcons.Circles fill="#98ff98" stroke="#98ff98" />
      </div>
    );
  } else {
    return (
      
      <motion.div
      
     
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.storyContainer}
        style={{
          width: "100%",
          backgroundImage: `url(${background})`,
        }}>
      
        {bgLoaded ? (
          <div>
           
            <div ref = {storyRef} className = {styles.storyIntro} >
           
            <div style={{ fontSize: "48px" }}>{title}</div>
            <div style={{ fontSize: "32px" }}> By {name}</div>
            <div ref={arrowRef} className={styles.arrow}>
            ↓ 
            </div>
          </div>
          <div ref = {closeButton} className={styles.closeButton} href = "/Stories">
        <a ref = {xbutton} className="fa-solid fa-xmark icons text-white" href="/stories"></a>
      </div>
            <div className={styles.templates}>
              {panels}
            </div>
            <div>
            <div className={styles.date}> Published -- {date}</div>
            <div className={styles.navigation}>
            <a  className={styles.previous} href={`${previousStory}`}>Previous Story</a>
            <a  className={styles.next} href={`${nextStory}`}>Next Story</a>
            </div>
      </div> 
          </div>
      
          
        ) : (
          <></>
        )}
      
      </motion.div>
      
    );
  }
}

export default Story;
