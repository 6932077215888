import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { generatePath } from "react-router-dom";
import { Link } from "react-router-dom";
import "./ApprovalGallery.css";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  where,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import Modal from "react-modal";
import defaultImage from "../Images/bg1.png";
import { set } from "mongoose";

const ARROW = require("../Images/left.png");

function StoryTile({ docTitle, title, description, image, approved, name, updateList}) {
  const firestore = getFirestore();
  const storyPath = useMemo(
    () => generatePath("/Story/:title", { title: docTitle }),
    [docTitle]
  );
  const [approval, setApproval] = useState(approved);
  const handleOnChange = (event) => {
    setTimeout(setApproval(!approval), 200);
    setTimeout(updateApproval, 500);
  };
  const handleOnDelete = (event) => {
      setTimeout(deleteDraft, 500);
  };
  const updateApproval = async () => {
    try {
      // Directly access the document using docTitle as the document ID
      const docRef = doc(firestore, "stories", docTitle);

      // Update the 'approved' field of the document
      await updateDoc(docRef, {
        approved: !approval,
      });

      // Log the updated approval status
      // console.log(`Story ${!approval ? "Approved!" : "Unapproved!"}`);
    } catch (error) {
      // console.log("Error updating document:", error);
    }
  };

  const deleteDraft = async (ev) => {
    console.log(ev)
    try {

      var confirmDeletion = window.confirm("Are you sure you want to delete this?");
      if (confirmDeletion) {
          console.log('deleting story with id: ' + docTitle);
         
        try {
        await deleteDoc(doc(firestore, "stories", docTitle));
        console.log(`Document with ID ${docTitle} successfully deleted.`);
        document.getElementById(docTitle).remove()
        
      } catch (error) {
        console.error("Error deleting document:", error);
      }
      } else {
          console.log('deletion cancelled');
      }
    
    } catch (error) {
      console.error("Error deleting document:", error);
   }
  };
  

  const desc = useMemo(
    () => description.substring(0, 20) + "...",
    [description]
  );



  return (
    <div id={docTitle}>
      <li>
      <button  className="deleteButton" onClick = {handleOnDelete}>Delete</button>
        <Link to={storyPath} target="_blank">
          <img className="imgFit" src={image} alt="Story" />
          <p className="thumbnailText">
            <span className="p_text">{title}</span>
            <span className="d_text">{name}</span>
            <span className="view">
              View
              <img src={ARROW} alt="View story" />
            </span>
          </p>
        </Link>

        <div className="approve">
          <form className="approval-form">
            <label class="switch">
              <input
                type="checkbox"
                id="approve-story"
                name="approve-story"
                checked={approval ? true : false}
                onChange={handleOnChange}
              />
              
              <span class="slider"></span>
            </label>
           
          </form>
          
        </div>
        
      </li>
    </div>
  );
}

function ApprovalGallery() {
  // Maintains the state values of the tags
  const [tags, setTags] = useState([
    { tprop: false, tlabel: "Identity" },
    { tprop: false, tlabel: "Social Connection" },
    { tprop: false, tlabel: "Emotional Regulation" },
    { tprop: false, tlabel: "Healthcare Providers" },
    { tprop: false, tlabel: "Clinical Stories" },
    { tprop: false, tlabel: "Caregivers" },
    { tprop: false, tlabel: "Community Members" },
    { tprop: false, tlabel: "Older Adults" },
    { tprop: false, tlabel: "Students" },
    { tprop: false, tlabel: "Artists/Musicians" },
    { tprop: false, tlabel: "Adults" },
    { tprop: false, tlabel: "Parents" },
    { tprop: false, tlabel: "ArtWorks" },
    { tprop: false, tlabel: "Educators" },
  ]);

  Modal.setAppElement("body");
  // Initiating Firebase
  const firestore = getFirestore();
  const [storyTiles, setStoryTiles] = useState([]);
  const [tagGraphics, setTagGraphics] = useState([]);
  const [docs, setDocs] = useState([]);
  const [maxStories, setMaxStories] = useState(12);
  const [curStory, setCurStory] = useState(0);

  // Retreiving data from Firebase
  const getData = useCallback(async () => {
    // Query the collection of stories
    const collections = query(
      collection(firestore, "stories"),
      orderBy("date", "desc")
    );
    const data = await getDocs(collections);
    setDocs(
      data.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        return docData;
      })
    );
  }, [firestore]);

  const tagSetup = useCallback(() => {
    let usedTags = tags.map((tag, index) => {
      for (let story = 0; story < docs.length; story++) {
        if (docs[story].approved && docs[story].tags != undefined) {
          for (let curTag = 0; curTag < docs[story].tags.length; curTag++) {
            if (docs[story].tags[curTag] == tag.tlabel) {
              return (
                <div
                  key={index}
                  className={`chip ${tag.tprop ? "chip-selected" : ""}`}
                  onClick={() => toggleTag(index)}>
                  {tag.tlabel}
                </div>
              );
            }
          }
        }
      }
    });
    setTagGraphics(usedTags);
  });




  const loadStories = useCallback(() => {
    if (docs.length == 0) {
      return null;
    }
    // Convert each doc to a story tile

    var stories = storyTiles.slice(0);
    var cur = curStory;

    while (cur < docs.length && stories.length < maxStories) {
      let doc = docs[cur];
      var tagsValid = true;
      for (let tag = 0; tag < tags.length; tag++) {
        if (tags[tag].tprop) {
          if (!(doc.tags != undefined && doc.tags.includes(tags[tag].tlabel))) {
            tagsValid = false;
          }
        }
      }
      if (tagsValid) {
        const panelWithDescription = doc.panels.find(
          (panel) => panel.Description
        );
        const panelWithImage = doc.panels.find((panel) => {
          let mediaKeys = ["Media", "Media2", "Media3"];
          let keys = Object.keys(panel);
          for (let mediaKey = 0; mediaKey < keys.length; mediaKey++) {
            if (
              mediaKeys.includes(keys[mediaKey]) &&
              panel[keys[mediaKey]].type === "upload"
            ) {
              return true;
            }
          }
          return false;
        });

        if (panelWithImage == null) {
          var previewImage = null;
          doc.panels.forEach((panel) => {
            if (panel.Media != null && panel.Media.type == "youtube") {
              previewImage =
                "https://img.youtube.com/vi/" +
                panel.Media.embedId +
                "/maxresdefault.jpg";
            }
          });
        }

        if (panelWithImage == null) {
          doc.panels.forEach((panel) => {
            if (panel.Media != null && panel.Media.type == "vimeo") {
              previewImage =
                "https://vumbnail.com/" + panel.Media.embedId + "_large.jpg";
            }
          });
        }

        // Find the correct media key for the panel with an image
        let mediaKey = "";
        if (panelWithImage) {
          for (let i = 1; ; ++i) {
            mediaKey = i === 1 ? "Media" : `Media${i}`;
            if (mediaKey in panelWithImage) {
              if (panelWithImage[mediaKey].type === "upload") {
                break;
              }
            }
          }
        }

        stories.push(
          <StoryTile
            key={doc.id}
            docTitle={doc.id}
            title={doc.title ?? "Experience story"}
            description={
              panelWithDescription ? panelWithDescription.Description : "..."
            }
            image={
              panelWithImage
                ? panelWithImage[mediaKey].url
                : previewImage
                ? previewImage
                : defaultImage
            }
            
            name = {doc.name  + "--" + doc.date.toDate().toDateString()}
            approved={doc.approved}
          />
        );
      }
      cur += 1;
    }

    //Old code for grabbing the stories to go in the gallery, replaced it with the above code,
    //honestly not completely sure the new stuff is better but I think it should be since it
    //doesn't loop through the entire docs array every time.

    /*const stories = docs.map((doc) => {
      for (let tag = 0; tag < tags.length; tag++) {
        if (tags[tag].tprop) {
          if (!(doc.tags != undefined && doc.tags.includes(tags[tag].tlabel))) {
            return null;
          }
        }
      }

      const panelWithDescription = doc.panels.find(
        (panel) => panel.Description
      );
      const panelWithImage = doc.panels.find((panel) => {
        let mediaKeys = ["Media", "Media2", "Media3"];
        let keys = Object.keys(panel);
        for (let mediaKey = 0; mediaKey < keys.length; mediaKey++) {
          if (
            mediaKeys.includes(keys[mediaKey]) &&
            panel[keys[mediaKey]].type === "upload"
          ) {
            return true;
          }
        }
        return false;
      });

      if (panelWithImage == null) {
        var previewImage = null
        doc.panels.forEach((panel) => {
          if (panel.Media != null && panel.Media.type == "youtube") {
            previewImage =
              "https://img.youtube.com/vi/" +
              panel.Media.embedId +
              "/maxresdefault.jpg";
          }
        });
      }

      if (panelWithImage == null) {
        doc.panels.forEach((panel) => {
          if (panel.Media != null && panel.Media.type == "vimeo") {
            previewImage =
              "https://vumbnail.com/" + panel.Media.embedId + "_large.jpg";
          }
        });
      }

      // Find the correct media key for the panel with an image
      let mediaKey = "";
      if (panelWithImage) {
        for (let i = 1; ; ++i) {
          mediaKey = i === 1 ? "Media" : `Media${i}`;
          if (mediaKey in panelWithImage) {
            if (panelWithImage[mediaKey].type === "upload") {
              break;
            }
          }
        }
      }

      return (
        <StoryTile
          key={doc.id}
          docTitle={doc.id}
          title={doc.title ?? "Experience story"}
          description={
            panelWithDescription ? panelWithDescription.Description : "..."
          }
          image={panelWithImage ? panelWithImage[mediaKey].url : (previewImage ? previewImage : defaultImage)}
          approved={doc.approved}
        />
      );
    });*/

    // console.log(stories);
    setCurStory(cur);
    setStoryTiles(stories);
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    tagSetup();
    loadStories();
  }, [docs]);

  useEffect(() => {
    tagSetup();
    setMaxStories(12);
    setCurStory(0);
    setStoryTiles([]);
  }, [tags]);

  useEffect(() => {
    if (storyTiles.length == 0) {
      loadStories();
    }
  }, [storyTiles]);

  useEffect(() => {
    loadStories();
  }, [maxStories]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log("Reached the bottom!");
        // console.log(maxStories);
        setMaxStories(maxStories + 12);
      }
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, [maxStories]);

  const toggleTag = (index) => {
    // Create a new array with all tags toggled based on the index
    const newTags = tags.map((tag, idx) =>
      idx === index ? { ...tag, tprop: !tag.tprop } : tag
    );
    setTags(newTags); // Update the tags state
  };

  return (
    <>
      <div className="tag-chips">{tagGraphics}</div>

      {storyTiles}
    </>
  );
}

export default ApprovalGallery;
