import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./components/Homepage/Homepage";
import CreateStory from "./components/CreateStory/CreateStory";
import EditStory from "./components/EditStory/EditStory";
import Stories from "./components/ViewStory/Stories";
import Story from "./components/Story/Story";
import About from "./components/AboutPage/AboutPage";
import MapPage from "./components/Map/MapPage";
import SharePage from "./components/SharePage/SharePage";
import { AnimatePresence } from "framer-motion";
import StoryApproval from "./components/ApproveStory/StoryApproval";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/Stories" element={<Stories />} />
          <Route path="/CreateStory" element={<CreateStory />} />
          <Route path="/EditStory" element={<EditStory />} />
          <Route path="/Story/:title" element={<Story />} />
          <Route path="/About" element={<About />} />
          <Route path="/Approval" element={<StoryApproval />} />
          <Route path="/Map" element={<MapPage />} />
          <Route path="/Share" element={<SharePage />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
