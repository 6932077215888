import React from "react";
import MediaQuery from "react-responsive";
import styles from "./AboutPage.module.css";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

import { useRef, useState } from "react";
import "./AboutPage.module.css";
import Map from "../Map/Map";

const AboutPage = () => {
  const [formSubmitButtonValue, setFormSubmitButtonValue] = useState("");
  const [formVal, setFormVal] = useState();

  // Sending comment to email using Emailjs, https://www.emailjs.com/docs/examples/reactjs/
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_o4lzh6l",
        "template_sw4mmns",
        form.current,
        "FLK6hreOiEnOUcwRY"
      )
      .then(
        (result) => {
          setFormSubmitButtonValue("Successfully submitted!");
          setFormVal("");

          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };

  return (
  
    <div className={styles.aboutPage}>
      <MediaQuery minWidth={700}>
        <div >
          <div style = {{width: '80vw', marginLeft: "auto", marginRight: "auto"}}>
          <div className= {styles.headerImage}>
            <p className={styles.headText}>About the Story Lab</p>
            </div>
            <p className={styles.mainText}>
              The Music & Health Story Lab is an ongoing public-facing research
              initiative created by{" "}
              <a
                href="https://cfa.arizona.edu/profile/jgubner/"
                target="_blank">
                Dr. Jennie Gubner
              </a>{" "}
              at the University of Arizona. It features a selection of stories
              about relationships between music, health and wellness made by
              undergraduate and graduate students in Gubner’s courses. Drawing
              on multimodal and ethnographic storytelling techniques, the
              platform seeks to diversify conversations about music and health
              and inspire caregivers, artists, healthcare professionals and
              community members to critically reimagine how music and the arts
              can be harnessed as tools in living healthier lives and building
              healthier, more inclusive, equitable, and age-friendly
              communities.
            </p>
            <br />
            <br />
            <p style={{ fontSize: "36px", fontWeight: "bold", textAlign: "center", marginLeft: 'auto', marginright: "auto" }}>
              About Dr. Jennie Gubner
            </p>
            <p className={styles.mainText}>
              Dr. Gubner is a PhD ethnomusicologist, visual ethnographer,
              community-engaged researcher, intercultural arts and health
              specialist, and Senior Atlantic Fellow for Equity in Brain Health
              at the{" "}
              <a href="https://www.gbhi.org/" target="_blank">
                Global Brain Health institute
              </a>
              . Over the last decade, Gubner has been designing, teaching and
              researching interdisciplinary university courses about health,
              wellness and creativity from the perspective of the arts. In her
              hands-on fieldwork courses, students explore relationships between
              music and health through the creation of collaborative,
              community-based digital storytelling projects. The most recent
              course she designed is The Music, Health & Wellness Story Lab.
            </p>
            <br />
            <br />
            <p style={{ fontSize: "36px", fontWeight: "bold" , textAlign: "center", marginLeft: 'auto', marginright: "auto" }}>
            Acknowledgements
            </p>
            <p className={styles.mainText}>
              This site was created by the University of Arizona Center for
              Digital Humanities with funds from the{" "}
              <a href="https://innovationsinaging.uahs.arizona.edu/">
                Innovations in Healthy Aging
              </a>{" "}
              (IHA) Strategic Initiative. Thank you to the leadership of IHA for
              supporting the value of arts and health research and education, to
              the Director of the{" "}
              <a href="https://digitalhumanities.arizona.edu" target="_blank">
                Center for Digital Humanities
              </a>{" "}
              Dr. Bryan Carter, and to all the developers involved in the
              creation of this project, especially Hamad Marhoon, Andrew
              Waugaman, and Joshua Tornquist, Jack Stevens. Thanks also to Dr. 
              Jennie Gubner’s grandfather, Ralph Delli-Bovi, whose illustrations 
              are used on the site and who inspired her to become an artist and storyteller.
            </p>
          </div>
        </div>
      </MediaQuery>

      {/* Mobile Page */}
      <MediaQuery maxWidth={700}>
        <div>
          <div>
            <div className= {styles.headerImage}>
            <p className={styles.headText}>The Music & Health Story Lab</p>
            </div>
            <p className={styles.mainText}>
              The Music & Health Story Lab is an ongoing public-facing research
              platform created by Dr. Jennie Gubner at the University of
              Arizona. Dr. Gubner is a PhD ethnomusicologist, community-engaged
              visual ethnographer, intercultural arts and health specialist, and
              Senior Fellow for Equity in Brain Health at the Global Brain
              Health Institute. Over the last decade, Gubner has been designing,
              teaching and researching interdisciplinary university courses
              about health, wellness and creativity from the perspective of the
              arts. In her hands-on fieldwork courses, students explore
              relationships between music and health through the creation of
              collaborative, community-based digital storytelling projects.
              Trained as an ethnographer and digital storyteller, Gubner
              believes person-centered experiential stories have the power to
              help individuals, caregivers, and healthcare professionals
              critically reimagine how music and the arts can be harnessed as
              tools in building healthier, more inclusive, equitable, and
              age-friendly communities. This site features a selection of
              stories made by students in Dr. Gubner’s courses, including her
              most recent course designed at the University of Arizona, The
              Music, Health & Wellness Story Lab (MUS429/529).
            </p>
            <br />
            <br />

            <p style={{ fontSize: "30px", fontWeight: "bold", justifyContent: "center", marginLeft: "auto", marginRight: "auto" }}>
            Acknowledgements
            </p>
            <p className={styles.mainText}>
              This digital platform was designed by students at the University
              of Arizona Center for Digital Humanities with funds from the
              University of Arizona Health Sciences Strategic Initiative,
              Innovations in Healthy Aging (IHA). Thank you to the leadership of
              the IHA initiative for understanding and supporting the value of
              arts and health research and education and thank you to all the
              developers involved in the creation of this project, especially
              Joshua Tornquist, Hamad Marhoon, and Andrew Waugaman. Thanks also 
              to Dr. Jennie Gubner’s grandfather, Ralph Delli-Bovi, whose illustrations are 
              used on the site and who inspired her to become an artist and storyteller.
            </p>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

export default AboutPage;
