import "./Map.css";
import React, { useEffect, useState } from "react";
import MapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { motion } from "framer-motion";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGNiLXdlYnNpdGUiLCJhIjoiY2w5eGFwanc0MDNqdzN1cGNqaXk0NHljZSJ9.ynHhk1K9FeZHrZ_7z9S7qw";
// eslint-disable-next-line import/no-webpack-loader-syntax

function Map(props) {
  const [showPopup, setShowPopup] = useState(null);
  const [locations, setLocations] = useState([]);

  const lng = props.longitude;
  const lat = props.latitude;
  const zm = props.zoom;

  const firestore = getFirestore();

  const collections = query(collection(firestore, "stories"));

  useEffect(() => {
    const getLocations = async () => {
      const data = await getDocs(collections);
      setLocations(
        data.docs.reduce(function (filtered, doc) {
          let docData = doc.data();
          if (
            docData.address != undefined &&
            docData.address != null &&
            docData.approved == true
          ) {
            // console.log(docData)
            let previewImage = "";
            const panelWithImage = docData.panels.find((panel) => {
              let mediaKeys = ["Media", "Media2", "Media3"];
              let keys = Object.keys(panel);
              for (let mediaKey = 0; mediaKey < keys.length; mediaKey++) {
                if (
                  mediaKeys.includes(keys[mediaKey]) &&
                  panel[keys[mediaKey]].type === "upload"
                ) {
                  return true;
                }
              }
              return false;
            });
            let mediaKey = "";
            if (panelWithImage) {
              for (let i = 1; ; ++i) {
                mediaKey = i === 1 ? "Media" : `Media${i}`;
                if (mediaKey in panelWithImage) {
                  if (panelWithImage[mediaKey].type === "upload") {
                    break;
                  }
                }
              }
              previewImage = panelWithImage[mediaKey].url;
            } else {
              docData.panels.forEach((panel) => {
                if (panel.Media != null && panel.Media.type == "youtube") {
                  previewImage =
                    "https://img.youtube.com/vi/" +
                    panel.Media.embedId +
                    "/maxresdefault.jpg";
                }
              });
            }

            if (panelWithImage == null) {
              docData.panels.forEach((panel) => {
                if (panel.Media != null && panel.Media.type == "vimeo") {
                  previewImage =
                    "https://vumbnail.com/" +
                    panel.Media.embedId +
                    "_large.jpg";
                }
              });
            }

            filtered.push({
              longitude: docData.longitude,
              latitude: docData.latitude,
              image: previewImage,
              id: docData.address.value.place_id,
              storyId: doc.id,
            });
          }
          return filtered;
        }, [])
      );
    };
    getLocations();
  }, []);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setShowPopup(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <div className="mapContainer">
        <MapGL
          initialViewState={{
            longitude: lng,
            latitude: lat,
            zoom: zm,
          }}
          style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          mapStyle="mapbox://styles/mapbox/outdoors-v12">
          {locations.map((location) => (
            <Marker
              key={location.id}
              latitude={location.latitude}
              longitude={location.longitude}>
              <a href={"Story/" + location.storyId}>
                <img className="image-style" src={location.image} />
              </a>
            </Marker>
          ))}

          <NavigationControl />
        </MapGL>
      </div>
    </motion.div>
  );
}

export default Map;
