import React, { forwardRef, useCallback } from "react";

/**
 * Hidden file upload <input> element
 */
const HiddenFileUpload = forwardRef(({ imageOnly = true, onChange }, ref) => {
  // onChange: function(File) -- callback for when the user inputs an image
  let accept = "image/png, image/jpg, image/jpeg, image/gif";
  // TODO: should we make it accept "image/*, video/*, audio/*"  ?
  if (!imageOnly) {
    accept += ", video/mp4, audio/*";
  }

  const onFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        // Only call the onChange if a File was uploaded
        onChange(file);
      }
    },
    [onChange]
  );
  return (
    <input
      type="file"
      accept={accept}
      onChange={onFileChange}
      ref={ref}
      style={{ display: "none" }}
    />
  );
});
export default HiddenFileUpload;
