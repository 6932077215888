/**
 * Gets the file type for a file
 * @param {File} file File object
 * @returns file type (image, video, or audio)
 */
export function getFileType(file) {
    if (file === null || file === undefined) {
        return;
    } else if (file.startsWith("image")) {
        return "image";
    } else if (file.startsWith("video")) {
        return "video";
    } else if (file.startsWith("audio")) {
        return "audio";
    } else {
        // TODO: what to do in the case we don't recognize the file type?
        // Seems very unlikely that it would happen
        return "unknown";
    }
}