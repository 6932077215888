import React from "react";
import Map from "./Map";
import "./Map.css";

const MapPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "10vh",
        flexDirection: "column",
        gap: "5vh",
      }}>
      <div className="headerImageMap">
        <div className="digital-story-gallery-title">Map</div>
      </div>
      <Map latitude={32.2} longitude={-110.99} zoom={10.4} />
    </div>
  );
};

export default MapPage;
