import { useEffect, useCallback, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

/**
 * Prevents the user from navigating (hacky implementation)
 * @param {boolean} when If navigation should be blocked
 * @param {string} message Message to confirm navigation
 */
export function useBlocker(when, message) {
    const { navigator } = useContext(NavigationContext);
    const blocker = useCallback(tx => {
        if (window.confirm(message) === true) {
            tx.retry();
        }
    }, [message]);

    useEffect(() => {
        if (!when) {
            // Don't need to block navigation
            return;
        }
        const unblock = navigator.block(tx => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
}