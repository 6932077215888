import React from "react";
import styles from "./YoutubeEmbed.module.css";

/**
 * YouTube embed compoent
 * @param {{embedId: string}} props YouTube video ID
 * @returns YouTube embed component
 */
export default function YoutubeEmbed({ embedId, width, height }) {
  return (
    <div className={styles.videoResponsive}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{
          width: width,
          height: height,
        }}
      />
    </div>
  );
}
