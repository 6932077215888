import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import styles from "./panelStyle.module.css";
import UploadMedia from "../UploadUtilities/UploadMedia";
import MediaPreview from "./MediaPreview";
import hiddenFileUpload from "../UploadUtilities/HiddenFileUpload";

/**
 * @param {{data: {Description: string, Media}, removePanel: function(), updatePanel: function({Description: string, Media})}} props Props to the panel
 * @param {panelOption: number} determines which panel will be rendered
 *
 * @returns {GenericPanel: component} shownto user in createStory
 */

export default function GenericPanel({
  data,
  removePanel,
  updatePanel,
  moveUp,
  moveDown,
  panelOption,
}) {
  //=========================================================================================
  if (data.Description === undefined) {
    data.Description = "";
  }
  
  const remainingCharacters = 250 - data.Description.length;
  const remainingCharactersHead = 250 - data.Description.length;
  // components and middlewear code

  // default number of images is 1 per slide

  // hybrid setMedia function to facilitate both one and multi image slides (basically just slide 5)
  function setMedia(newMedia) {
    updatePanel({
      ...data,
      Media: newMedia,
    });
  }

  // panel background component
  // used universally in all panels as the main container inside which all content resides

  function Trash() {
    return (
      <div className={styles.trash} onClick={removePanel}>
        <i className="fa-solid fa-trash icons"> </i>
      </div>
    );
  }

  function TrashBlack() {
    return (
      <div className={styles.trash2} onClick={removePanel}>
        <i className="fa-solid fa-trash icons text-black"></i>
      </div>
    );
  }

  function MoveUp() {
    return (
      <div className={styles.upArrow} onClick={moveUp}>
        <i className="fa-solid fa-arrow-up icons text-black"></i>
      </div>
    );
  }
  function Movedown() {
    return (
      <div className={styles.downArrow} onClick={moveDown}>
        <i className="fa-solid fa-arrow-down-long icons text-black"></i>
      </div>
    );
  }

  //==============================================================================
  // Switch statement / return

  /**
   * <Rant>
   * While I would have preferred each panel option to be returned as a series of componenets
   * to optimize the modularity of the generic panel, unfortunately this cannot be done.
   * This is due to the limitations of React's useCallBack in a nested component return
   * the textarea cannot be the child of any externally defined component, or returned in
   * its own component, as changing the state of the textarea in this case causes a rippling
   * re-render of the components, which un-focuses the textarea, and as a result it can only be
   * filled one character at a time, which is obviously unacceptable.
   * for this reason, panels 1->4 have to be mostly hardcoded with the exception of the trash and image
   * components, otherwise the textarea is interferred with and unuable.
   *
   * The same thing is true for image classes. I cannot believe it, this is a joke. A joke!
   * </Rant>
   */

  /**
   * Panel switch case, returns JSX for the appropriate panel
   */
  switch (panelOption) {
    case 1:
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
          <div className={styles.largeImageParent}>
            {data.Media === null ? (
              <UploadMedia setMedia={setMedia} width="35vw" height="20vw" />
            ) : (
              <div style={{ position: "relative" }}>
                <Trash />
                <MediaPreview
                  media={data.Media}
                  imageClass={styles.largeImagePreview}
                />
                <div className={styles.trash}>
                  <i
                    onClick={() => setMedia(null, "Media")}
                    className="fa-solid fa-trash icons"></i>
                </div>
              </div>
            )}
          </div>
         
          <div className={styles.textContainer}>
            <textarea
              className={styles.imageCaption}
              id="imageDescription" // Add an id
              name="imageDescription" // Add name
              placeholder="Media Description"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
              maxLength="250"
              />
              <div className={styles.characterCounter}>
              {remainingCharacters} characters remaining
              </div>   
              </div>
          </div>
     
      );

    case 2:
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
      
          <div className={styles.textContainer}>
            <textarea
              className={styles.imageCaption}
              placeholder="Media Description"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
              maxLength="250"
              />
            <div className={styles.characterCounter}>
              {remainingCharacters} characters remaining
            </div>
          </div>
          <div className={styles.largeImageParent}>
            {data.Media === null ? (
              <UploadMedia setMedia={setMedia} width="35vw" height="20vw" />
            ) : (
              <div style={{ position: "relative" }}>
                <div className={styles.trash}>
                  <i
                    onClick={() => setMedia(null, "Media")}
                    className="fa-solid fa-trash icons"></i>
                </div>
                <MediaPreview
                  media={data.Media}
                  imageClass={styles.largeImagePreview}
                />
              </div>
            )}
          </div>
        </div>
      );

      case 9:
        return (
          <div className={styles.panelContainer}>
            <Trash />
            <MoveUp />
            <Movedown />
            <div className={styles.largeImageParent}>
              {data.Media === null ? (
                <UploadMedia setMedia={setMedia} width="35vw" height="20vw" />
              ) : (
                <div style={{ position: "relative" }}>
                  <div className={styles.trash}>
                    <i
                      onClick={() => setMedia(null, "Media")}
                      className="fa-solid fa-trash icons"></i>
                  </div>
                  <MediaPreview
                    media={data.Media}
                    imageClass={styles.largeImagePreview}
                  />
                </div>
              )}
            </div>
          </div>
        );
      
    case 11:
          return (
            <div className={styles.panelContainer}>
              <Trash />
              <MoveUp />
              <Movedown />
              <div className={styles.largeImageParentNoBackground}>
                {data.Media === null ? (
                  <UploadMedia setMedia={setMedia} width="35vw" height="20vw" />
                ) : (
                  <div style={{ position: "relative" }}>
                    <div className={styles.trash}>
                      <i
                        onClick={() => setMedia(null, "Media")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                    <MediaPreview
                      media={data.Media}
                      imageClass={styles.largeImagePreview}
                    />
                  </div>
                )}
              </div>
            </div>
          );
          
    case 3:
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
          <div style={{ display: "flex", flexDirection: "row", gap: "3%" }}>
            <textarea
              className={styles.sideCaption}
              placeholder="Media Description"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
              maxLength="250"
            />
            <div className={styles.characterCounter}>
            {remainingCharacters} characters remaining
            </div>
            <div>
              {data.Media === null ? (
                <UploadMedia setMedia={setMedia} width="30vw" height="20vw" />
              ) : (
                <div style={{ position: "relative" }}>
                  <MediaPreview
                    media={data.Media}
                    imageClass={styles.sideImage}
                    vertical={true}
                  />
                  <div className={styles.trash}>
                    <i
                      onClick={() => setMedia(null, "Media")}
                      className="fa-solid fa-trash icons"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );

    case 4:
      // console.log("case 4 - generating panel 4");
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "3%",
            }}>
            <textarea
              className={styles.sideCaption}
              placeholder="Media Description"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
              maxLength="250"
              />
            <div className={styles.characterCounter}>
              {remainingCharacters} characters remaining
            </div>
            <div>
              {data.Media === null ? (
                <UploadMedia setMedia={setMedia} width="30vw" height="20vw" />
              ) : (
                <div style={{ position: "relative" }}>
                  <MediaPreview
                    media={data.Media}
                    imageClass={styles.sideImage}
                    vertical={true}
                  />
                  <div className={styles.trash}>
                    <i
                      onClick={() => setMedia(null, "Media")}
                      className="fa-solid fa-trash icons"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    
      case 10:
        // console.log("case 4 - generating panel 4");
        return (
          <div className={styles.panelContainer}>
            <Trash />
            <MoveUp />
            <Movedown />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "3%",
                alignItems: "center",
                paddingTop: "2vw",
                paddingBottom: "2vw",
              }}>
              <div className={styles.characterCounter}>
                {remainingCharacters} characters remaining
              </div>
              <textarea
                className={styles.authorCaption}
                placeholder="Media Description"
                value={data.Description}
                onChange={(e) =>
                  updatePanel({
                    ...data,
                    Description: e.target.value,
                  })
                }
                maxLength="250"
              />
              
              
              <div>
                {data.Media === null ? (
                  <UploadMedia setMedia={setMedia} width="15vw" height="15vw" />
                ) : (
                  <div style={{ position: "relative" }}>
                    <MediaPreview
                      media={data.Media}
                      imageClass={styles.authorImage}
                      vertical={true}
                    />
                    <div className={styles.trash}>
                      <i
                        onClick={() => setMedia(null, "Media")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );

        case 13:
          // console.log("case 4 - generating panel 4");
          return (
            <div className={styles.panelContainer}>
              <Trash />
              <MoveUp />
              <Movedown />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "3%",
                  alignItems: "center",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                }}>
                <div className={styles.characterCounter}>
                  {remainingCharacters} characters remaining
                </div>
                <textarea
                  className={styles.authorCaption}
                  placeholder="Media Description"
                  value={data.Description}
                  onChange={(e) =>
                    updatePanel({
                      ...data,
                      Description: e.target.value,
                    })
                  }
                  maxLength="250"
                />
                
                
                <div>
                  {data.Media === null ? (
                    <UploadMedia setMedia={setMedia} width="15vw" height="15vw" />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <MediaPreview
                        media={data.Media}
                        imageClass={styles.authorImage}
                        vertical={true}
                      />
                      <div className={styles.trash}>
                        <i
                          onClick={() => setMedia(null, "Media")}
                          className="fa-solid fa-trash icons"></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
  
    case 5:
      // console.log("case 5 - generating panel 5");
      return (
        <div className={styles.panelContainer}>
          <Trash /> 
          <MoveUp />
          <Movedown />
      
            <div className={styles.textContainer}>
            
              <textarea
                className={styles.smallCaption}
                placeholder="Story Intro"
                value={data.Description}
                onChange={(e) =>
                  updatePanel({
                    ...data,
                    Description: e.target.value,
                  })
                }
              maxLength="250"
                />
              <div className={styles.characterCounter}>
                {remainingCharacters} characters remaining
              </div>
              </div>
          
        </div>
      );

    case 6:
      // console.log("case 6 - generating panel 6");
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
          
          <div className={styles.textContainer}>
            <textarea
              className={styles.storyTitle}
              placeholder="Subheading"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
              maxLength="250"
              />
            <div className={styles.characterCounter}>
              {remainingCharacters} characters remaining
             </div>
          </div>
        </div>
     
      );

      case 14:
      // console.log("case 5 - generating panel 5");
      return (
        <div className={styles.panelContainer}>
          <Trash /> 
          <MoveUp />
          <Movedown />
      
            <div className={styles.textContainer}>
            
              <textarea
                className={styles.smallCaption}
                placeholder="Text"
                value={data.Description}
                onChange={(e) =>
                  updatePanel({
                    ...data,
                    Description: e.target.value,
                  })
                }
             
                />
              </div>
          
        </div>
      );

      case 12:
        // console.log("case 6 - generating panel 6");
        return (
          <div className={styles.panelContainer}>
            <Trash />
            <MoveUp />
            <Movedown />
            
            <div className={styles.textContainer}>
              <textarea
                className={styles.storyCitations}
                placeholder="Photo Citations"
                value={data.Description}
                onChange={(e) =>
                  updatePanel({
                    ...data,
                    Description: e.target.value,
                  })
                }
                maxLength="250"
                />
              <div className={styles.characterCounter}>
                {remainingCharacters} characters remaining
               </div>
            </div>
          </div>
       
        );

    // Has a description AND a title
    case 7:
      // console.log("case 7 - generating panel 7");
      return (
        <div className={styles.panelContainer}>
          <Trash />
          <MoveUp />
          <Movedown />
          <div style={{display: "flex", flexDirection: "row", gap: "3%"}}>
          <div className={styles.textContainer}>
            <textarea
              className={styles.storyTitle}
              placeholder="Heading"
              value={data.Description}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Description: e.target.value,
                })
              }
            maxLength="250"
            />
            <div className={styles.characterCounter}>
              {remainingCharactersHead} characters remaining
            </div>
            <textarea
              className={styles.smallCaption}
              placeholder="Subheading"
              value={data.Title}
              onChange={(e) =>
                updatePanel({
                  ...data,
                  Title: e.target.value,
                })
              }
              maxLength="250"
            />
            <div className={styles.characterCounter}>
              {remainingCharacters} characters remaining
            </div>
          </div>
        </div>
      </div>
      );

    /* ---------------------------------------------------------------------------------- */
    /* fifth panel option return - most complex one */
    /* this comment exists to create a visual break to improve readibility of the page */
    /* ---------------------------------------------------------------------------------- */

    case 8:
      // // console.log(data);

      function setMedia2(newMedia, int) {
        let mediaKey;
        if (int === "one") {
          mediaKey = "Media";
        } else if (int === "two") {
          mediaKey = "Media2";
        } else if (int === "three") {
          mediaKey = "Media3";
        } else if (int === "four") {
          mediaKey = "Media4";
        } else if (int === "five") {
          mediaKey = "Media5";
        } else if (int === "six") {
          mediaKey = "Media6";
        } else if (int === "seven") {
          mediaKey = "Media7";
        } else if (int === "eight") {
          mediaKey = "Media8";
        } else {
          mediaKey = "Media8";
          // console.error("Error! unknown media key used in panel 5. Stop messing around with inspect element.");
        }
        updatePanel({
          ...data,
          [mediaKey]: newMedia,
        });
      }

      return (
        <div className={styles.panelContainer}>
          <TrashBlack />

          <div className={styles.panel5Parent}>
            <div className={styles.topRow}>
              {/* images for top row */}

              <div className={styles.botRowLeft}>
                {data.Media === null ? (
                  <UploadMedia setMedia={(media) => setMedia2(media, "one")} />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "one")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.botRowMid}>
                {data.Media2 === null ? (
                  <UploadMedia setMedia={(media) => setMedia2(media, "two")} />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media2}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "two")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.botRowRight}>
                {data.Media3 === null ? (
                  <UploadMedia
                    setMedia={(media) => setMedia2(media, "three")}
                  />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media3}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "three")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.midRow}>
              <div className={styles.midRowLeft}>
                {data.Media4 === null ? (
                  <UploadMedia setMedia={(media) => setMedia2(media, "four")} />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media4}
                      imageClass={styles.midRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "four")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.midRowRight}>
                {data.Media5 === null ? (
                  <UploadMedia setMedia={(media) => setMedia2(media, "five")} />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media5}
                      imageClass={styles.midRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "five")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.botRow}>
              <div className={styles.botRowLeft}>
                {data.Media6 === null ? (
                  <UploadMedia setMedia={(media) => setMedia2(media, "six")} />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media6}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "six")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.botRowMid}>
                {data.Media7 === null ? (
                  <UploadMedia
                    setMedia={(media) => setMedia2(media, "seven")}
                  />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media7}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "seven")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.botRowRight}>
                {data.Media8 === null ? (
                  <UploadMedia
                    setMedia={(media) => setMedia2(media, "eight")}
                  />
                ) : (
                  <div>
                    <MediaPreview
                      media={data.Media8}
                      imageClass={styles.topRowImage}
                    />
                    <div className={styles.imageTrash3_1}>
                      <i
                        onClick={() => setMedia2(null, "eight")}
                        className="fa-solid fa-trash icons"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );

    default:
      console.error("Unknown panel type requested");
      break;
  }
}
