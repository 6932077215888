
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import logo from "../Images/logo-modified-thick-black.png";
import SideNav from "./SideNav";

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import background from  "../Images/paperback.jpg";

const sites = [
  { name: "Gallery", path: "/Stories" },
  { name: "Map", path: "/Map" },
  { name: "About", path: "/About" },
];





const Navbar = () => {

  const brandRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (brandRef.current) {
        brandRef.current.style.opacity = window.scrollY > 100 ? '0' : '1';
      }
    };


    // Add the event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty array means this effect runs once after the initial render


  const [isOpen, setIsOpen] = useState(false);

  const toggleSidenav = () => {
    // Function to toggle sidenav visibility
    setIsOpen(!isOpen);
  };

  return (
    <>
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 0.2 }}>

    <MDBNavbar
      style={{
        backgroundColor: 'transparent',
        position:'fixed',
        width: "100%",
        boxShadow: "none",
        zIndex: "5",

      }}>
      <MDBContainer fluid>
      
        <MDBNavbarBrand href="/">
        <div className={styles.rainbow_border}>
          <img
            src={logo}
            alt="Logo"
            className= {styles.navbar_logo}
          />
        </div>
          < a ref = {brandRef} href = '/' style={{ transition: 'opacity 0.3s ease-in-out' }} className= {styles.logoText} >MUSIC & HEALTH <br /> STORY LAB</a>
        </MDBNavbarBrand>
        <button onClick={toggleSidenav} className= {styles.menuButton} aria-controls="#sidenav-1" aria-haspopup="true">
              <MDBIcon icon="bars" />
            </button>
      </MDBContainer>
    </MDBNavbar>
  </motion.div>
  <SideNav isOpen={isOpen} toggleSidenav={toggleSidenav} />
  </>


  
    
  );
};

export default Navbar;





// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import styles from "./Navbar.module.css";
// import logo from "../Images/logo-modified-thick-black.png";

// import {
//   MDBContainer,
//   MDBNavbar,
//   MDBNavbarBrand,
//   MDBNavbarItem,
//   MDBNavbarLink,
//   MDBNavbarNav,
//   MDBNavbarToggler,
//   MDBIcon,
//   MDBCollapse,
//   MDBDropdown,
//   MDBDropdownToggle,
//   MDBDropdownMenu,
//   MDBDropdownItem,
//   MDBBtn,
// } from "mdb-react-ui-kit";
// import { motion } from "framer-motion";
// import background from  "../Images/paperback.jpg";

// const sites = [
//   { name: "Gallery", path: "/Stories" },
//   { name: "Map", path: "/Map" },
//   { name: "About", path: "/About" },
// ];

// const Navbar = () => {
//   const [openNavExternal, setOpenNavExternal] = useState(false);

//   return (
//     <motion.div
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 1 }}
//     transition={{ duration: 0.5, delay: 0.2 }}>

//     <MDBNavbar
//       style={{
//         backgroundImage: "offwhite",
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         zIndex: "1",
//         width: "100%",

//       }}>
//       <MDBContainer fluid>
      
//         <MDBNavbarBrand href="/">
//         <div className={styles.rainbow_border}>
//           <img
//             src={logo}
//             alt="Logo"
//             className= {styles.navbar_logo}
//           />
//         </div>
//           < a href = '/' className= {styles.logoText} >MUSIC & HEALTH <br /> STORY LAB</a>
//         </MDBNavbarBrand>
       
//         <MDBNavbarToggler
//           onClick={() => setOpenNavExternal(!openNavExternal)}>
//           <MDBIcon className= {styles.icon} icon='bars' fas />
//         </MDBNavbarToggler>
//         <MDBCollapse open={openNavExternal} navbar style={{backgroundColor: `white`, opacity: "80"}}>
//           <ul className="navbar-nav mr-auto">
//             <li className={styles.nav_item}>
//               <a  className={styles.nav_link} href="/share">SHARE<span className="sr-only">(current)</span></a>
//             </li>
//             <li className={styles.nav_item}>
//               <a className={styles.nav_link} href="/about">ABOUT</a>
//             </li>
//             <li className={styles.nav_item}>
//               <a  className={styles.nav_link}  href="/stories">STORIES</a>
//             </li>
//           </ul>
//         </MDBCollapse>
//       </MDBContainer>
//     </MDBNavbar>
//   </motion.div>
    
//   );
// };

// export default Navbar;
