import React from "react";
import YoutubeEmbed from "../UploadUtilities/YoutubeEmbed";
import Vimeo from "@u-wave/react-vimeo";
import ReactSoundCloud from "react-soundcloud-embedded";

export default function MediaPreview({ media, imageClass, vertical }) {
  //// console.log("mediapreview component reached");
  if (media === null) {
    return null;
  }

  //// console.log("past the point where media === null is checked");
  switch (media.type) {
    case "upload":
      if (Object.keys(media).includes("url")) {
        return <img className={imageClass} alt="Preview" src={media.url} />;
      }
      return (
        <img
          className={imageClass}
          alt="Preview"
          src={window.URL.createObjectURL(media.file)}
        />
      );
    case "youtube":
      return (
        <YoutubeEmbed
          embedId={media.embedId}
          width={vertical ? "30vw" : "35vw"}
          height="20vw"
        />
      );
    case "vimeo":
      return (
        <Vimeo
          video={media.embedId}
          width={vertical ? window.innerWidth * 0.3 : window.innerWidth * 0.35}
          height={window.innerWidth * 0.2}
        />
      );
    case "soundcloud":
      return (
        <ReactSoundCloud
          url={media.embedId}
          visual={false}
          width={vertical ? window.innerWidth * 0.3 : window.innerWidth * 0.35}
        />
      );
    default:
      console.error("Unknown media type " + media.type);
  }
}
