import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid4 } from "uuid4";

/**
 * Uploads a file to Firebase
 * @param {FirebaseStorage} storage Firebase storage to upload to
 * @param {File|string} file File to upload
 * @returns Download URL of the file uploaded
 */
export async function uploadFile(storage, file) {
  const storageRef = ref(storage, `StoryImages/${uuid4()}`);
  // console.log(file)
  if (file instanceof File) {
    return uploadBytes(storageRef, file, { contentType: file.type }).then(
      (snapshot) => getDownloadURL(snapshot.ref)
    );
  } else {
    return file;
  }
}

/**
 * Uploads multiple files concurrently to Firebase
 * @param {FirebaseStorage} storage Firebase storage to upload to
 * @param {File[]} files List of File objects to upload
 * @returns List of download URLs of the files uploaded
 */
export async function uploadFiles(storage, files) {
  // console.log(files)
  return Promise.all(files.map((file) => uploadFile(storage, file))).catch(
    (error) => {
      window.alert("File upload failed: " + error.message);
      return null;
    }
  );
}
