import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  useCallback,
} from "react";
// useState added so that different screen dimensions could be handled on their own

import styles from "./EditStory.module.css";
import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  getFirestore,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import panelOneImage from "../Panels/Images/Panel1.png";
import panelTwoImage from "../Panels/Images/Panel2.png";
import panelThreeImage from "../Panels/Images/Panel3.png";
import panelFourImage from "../Panels/Images/Panel4.png";
import panelFiveImage from "../Panels/Images/Panel5.png";
import panelSixImage from "../Panels/Images/Panel6.png";
import panelSevenImage from "../Panels/Images/Panel7.png";
import panelEightImage from "../Panels/Images/Panel8.png";
import panelNineImage from "../Panels/Images/Panel9.png";
import panelTenImage from "../Panels/Images/Panel10.png";
import panelELImage from "../Panels/Images/Panel11.png";
import panelTwelveImage from "../Panels/Images/Panel12.png";
import panelThirteenImage from "../Panels/Images/Panel13.png";
import panelFourTeenImage from "../Panels/Images/Panel14.png";

import GenericPanel from "../Panels/GenericPanel";

import placeholder from "../Images/placeholder.jpg";
import HiddenFileUpload from "../UploadUtilities/HiddenFileUpload";
import { getBackgroundImagePath } from "../../utils/background-images";
import { useBlocker } from "../../hooks/use-blocker";
import { getFileType } from "../../utils/file-type";
import { uploadFile, uploadFiles } from "../../utils/file-upload";
import { motion } from "framer-motion";

import GooglePlacesAutocomplete, {
  geocodeByLatLng,
} from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { mapValues } from "async";
const API_KEY_GOOGLE_MAPS = process.env.API_KEY_GOOGLE_MAPS;
// import { systemTypefaceName } from 'framer/build/render/fonts/LocalFontSource';

// random comment describing firebase issue was here, removed

/*
  I guess this is no longer the case? unsure.
  Media types as stored internally before sending to Firebase:
  
  UploadMedia:
  {
          type: "upload",
          file: File,
  }
  
  YoutubeMedia:
  {
          type: "youtube",
          embedId: string,
  }
  
  SoundcloudMedia:
  {
          type: "soundcloud",
          url: string,
  }
  
  */

function range(minInclusive, maxInclusive) {
  const r = [];
  for (let i = minInclusive; i <= maxInclusive; ++i) {
    r.push(i);
  }
  return r;
}

/**
 * Cleans extra keys out of the panel object
 * @param {object} panel Panel object to clean
 * @returns new panel with key removed
 */
function cleanPanel(panel) {
  return Object.keys(panel).reduce((result, key) => {
    // Don't allow "id"
    if (key !== "id") {
      result[key] = panel[key];
    }
    return result;
  }, {});
}



function checkPanelType(panels) {
  let desc = -1;
  let citation = -1;
  let final_check = true;
  let last_author = -1;
  let i = 0;
  for (const panel of panels) {
    console.log("TYPE: "+panel.PanelType);
    if (panel.PanelType === 5 && desc === -1) {
      desc = i;
    }
    if (panel.PanelType === 12) {
      citation = i;
    }
    if(panel.PanelType === 10 || panel.PanelType === 13){
      last_author = i;
    }
    if (citation !== -1){
      if (i > citation){
        final_check = false;
      }
    }
  i++;
  }

    if (desc === 0 && citation !== -1 && final_check && last_author !== -1 && citation - last_author == 1)  {
      return true;
    } else {
      return false;
    }
  }

/**
 * Gets the list of File objects in the panels
 * @param {object[]} panels List of panel objects
 * @returns list of File objects in the panels
 */
function getFilesFromPanels(panels) {
  const files = [];
  for (const panel of panels) {
    for (let i = 1; ; ++i) {
      const mediaKey = i === 1 ? "Media" : `Media${i}`;
      if (mediaKey in panel) {
        // Panel has Media for number `i`
        if (panel[mediaKey].type === "upload") {
          // It is a file upload
          if (panel[mediaKey].file != undefined) {
            files.push(panel[mediaKey].file);
          } else {
            files.push(panel[mediaKey]);
          }
        }
      } else {
        // Went through all the files in this panel
        break;
      }
    }
  }
  return files;
}

/**
 * Puts the file URLs in the panels with the appropriate keys
 * @param {object[]} panels Panel objects
 * @param {string[]} urls List of file URLs
 */
function putFileUrlsInPanels(panels, urls) {
  let urlNum = 0;
  for (const panel of panels) {
    for (let i = 1; ; ++i) {
      const mediaKey = i === 1 ? "Media" : `Media${i}`;
      if (mediaKey in panel) {
        if (
          panel[mediaKey].type === "upload" &&
          panel[mediaKey].url == undefined
        ) {
          let fileType = getFileType(panel[mediaKey].file.type);
          panel[mediaKey] = {
            type: "upload",
            fileType,
            url: urls[urlNum++],
          };
        } else {
          urlNum++;
        }
      } else {
        // Went through all the files in this panel
        break;
      }
    }
  }
}

/**
 * Checks if there are any Media fields left null in the story
 * @param {object[]} panels List of panel objects
 * @returns true if there was a null Media field, else false
 */
function anyNullMedia(panels) {
  return !panels.every((panel) => {
    for (let i = 1; ; ++i) {
      const mediaKey = i === 1 ? "Media" : `Media${i}`;
      if (mediaKey in panel) {
        // Panel has Media for number `i`
        if (panel[mediaKey] === null) {
          return false; // Has null media
        }
      } else {
        // Went through all the files in this panel
        return true;
      }
    }
  });
}

function Popup({ isOpen, onClose, confirmedSubmission, link,  content }) {
  const copyCodeClipboard = () => {
    navigator.clipboard.writeText(content).then(() => {
      alert("Code copied to clipboard!");
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };
  const copyLinkClipboard = () => {
      navigator.clipboard.writeText(link).then(() => {
        alert("Link copied to clipboard!");
      }, (err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    isOpen && (
      <div style={{
        position: 'fixed',
        top: '25%',
        left: '25%',
        width: '55%',
        height: '40%',
        backgroundColor: 'grey',
        border: '2px solid black',
        borderRadius: '10px',
        zIndex: 1000,
        overflow: 'auto',
        padding: '20px',
      }}>
        <h2 style={{color: "white"}}>Confirm your Story Submission!</h2>
        <p style={{color: "white"}}>Here is the link to your  new story ONCE submitted</p>
        <input
          type="text"
          value={link}
          style={{ width: '100%', marginBottom: '20px', fontSize: '2.5vh' }}
          readOnly={true}
        />
        <p style={{color: "white"}}>Here is the unique code for your story:</p>
        <input
          type="text"
          value={content}
          style={{ width: '100%', marginBottom: '20px', fontSize: '2.5vh' }}
          readOnly={true}
        />
        <div className = {styles.popupWindowButtons}>
        <button className={styles.copyButton} onClick={copyCodeClipboard} style={{ marginRight: '10px' }}>Copy code Clipboard</button>
        <button className={styles.copyButton} onClick={copyLinkClipboard} style={{ marginRight: '10px' }}>Copy Link to Clipboard</button>
        <button className={styles.closeButton} onClick={confirmedSubmission} style={{ marginRight: '10px' }}>Confirm Submission</button>
        <button className={styles.closeButton} onClick={onClose} style={{ marginRight: '10px' }}>Cancel Submission</button>
        </div>
      </div>
    )
  );
}

function EditStory() {
  // Initializing Firebase Firestore & Storage.
  const db = getFirestore();
  const storage = getStorage();

  // Password checker
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  //ID Checker
  const [docIndex, setDocIndex] = useState(-1);
  // Each of the input fields for the form.
  // User's name, email and title
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  // Track all the panels the user has added
  const [storyID, setStoryID] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [initPanels, setInitPanels] = useState([]);
  const [panelId, setPanelId] = useState(0);
  const [panels, setPanels] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [approved, setApproved] = useState(false);

  // contains the result of whether or not the window can be considered "ultrawide"
  // does this because different stylesheets exist for an ultrawide screen vs a regular screen
  const [isWideScreen, setIsWideScreen] = useState(
    window.innerWidth / window.innerHeight >= 24 / 9
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [tags, setTags] = useState([
    { tprop: false, tlabel: "Identity" },
    { tprop: false, tlabel: "Social Connection" },
    { tprop: false, tlabel: "Emotional Regulation" },
    { tprop: false, tlabel: "Healthcare Providers" },
    { tprop: false, tlabel: "Clinical Stories" },
    { tprop: false, tlabel: "Caregivers" },
    { tprop: false, tlabel: "Community Members" },
    { tprop: false, tlabel: "Older Adults" },
    { tprop: false, tlabel: "Students" },
    { tprop: false, tlabel: "Artists/Musicians" },
    { tprop: false, tlabel: "Adults" },
    { tprop: false, tlabel: "Parents" },
    { tprop: false, tlabel: "ArtWorks" },
    { tprop: false, tlabel: "Educators" },
  ]);

  const toggleTag = (index) => {
    setTags(
      tags.map((tag, i) => (i === index ? { ...tag, tprop: !tag.tprop } : tag))
    );
  };

  const toggleMultipleTags = (indices) => {
    setTags(
      tags.map((tag, i) =>
        indices.includes(i) ? { ...tag, tprop: !tag.tprop } : tag
      )
    );
  };

  const getTagsList = () => {
    let story_tags = [];

    for (let t of tags) {
      if (t.tprop) {
        story_tags.push(t.tlabel);
      }
    }

    return story_tags;
  };

  // for overlay that shows the recovery code.
  const [recoveryOverlayVisible, setRecoveryOverlayVisible] = useState(false);

  const [recoveryCode, setRecoveryCode] = useState("");

  const toggleRecoveryOverlay = () => {
    setRecoveryOverlayVisible(!recoveryOverlayVisible);
  };

  // handle the resizing of the page, switches between ultrawide style and standard width style
  // this is not used at all, I just forgot to remove it. oops.
  // - RUS
  useEffect(() => {
    function handleResize() {
      // updates the state based on the window size
      const newIsWideScreen = window.innerWidth / window.innerHeight >= 24 / 9;
      if (newIsWideScreen !== isWideScreen) {
        setIsWideScreen(newIsWideScreen);
        // // console.log("screen is narrow");
      } else {
        // // console.log("Screen is wide");
      }
      // sets the screen width and height so that mobile screens can be excluded later
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // boolean isWideScreen tells us if the screen is in ultrawide mode
  }, [isWideScreen]);

  useEffect(() => {
    if (address !== null && address !== "" && address !== undefined) {
      geocodeByAddress(address["label"])
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          // console.log("Got coords: " + lat + "_" + lng);
          setLongitude(lng);
          setLatitude(lat);
        });
    } else {
      setLongitude(null);
      setLatitude(null);
    }
  }, [address]);

  const PasswordProtectedPage = ({ setPasswordCorrect }) => {
    const [password, setPassword] = useState("");

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    const handleFormSubmit = (event) => {
      event.preventDefault();

      // Check if the password is correct
      const correctPassword = "iameditor";
      if (password === correctPassword) {
        setPasswordCorrect(true);
      }
    };



    return (
      /* password protected page is not yet fully styled - mostly is a skeleton*/
      <div className="passwordPage">
        <form onSubmit={handleFormSubmit} className="passwordForm">
          <p className="password-hero">Story Editing</p>
          <p className="password-text">
            If you are an authorized user, please enter your password.
          </p>
          <br></br>
          <label htmlFor="password">Enter Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            className="password-input"
          />
          <button type="submit" className="password-submit">
            Submit
          </button>
        </form>
      </div>
    );
  };

  const StoryIDRequest = ({ setDocIndex }) => {
    const handleIDChange = (event) => {
      setStoryID(event.target.value);
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault();

      // Check if the ID exists
      const collections = query(collection(db, "stories"));
      const data = await getDocs(collections);
      for (let document = 0; document < data.docs.length; document++) {
        if (data.docs[document].id == storyID) {
          setDocIndex(document);
          loadStory(document, data);
        }
      }
    };

    return (
      /* ID request page is not yet fully styled - mostly is a skeleton*/
      <div className="passwordPage">
        <form onSubmit={handleFormSubmit} className="passwordForm">
          <p className="password-hero">Enter Story ID</p>
          <p className="password-text">
            Please enter a valid story ID (when viewing a story, the ID will be
            the last part of the link)
          </p>
          <br></br>
          <label htmlFor="password">Enter ID:</label>
          <input
            type="id"
            id="id"
            value={storyID}
            onChange={handleIDChange}
            className="password-input"
          />
          <button type="submit" className="password-submit">
            Submit
          </button>
        </form>
      </div>
    );
  };

  function getFirebaseArray(firebaseArr) {
    if (firebaseArr == undefined) {
      return [];
    }
    var array = [];
    for (let i = 0; i < firebaseArr.length; i++) {
      if (firebaseArr[i].mapValue != undefined) {
        array[i] = getFirebaseObject(firebaseArr[i].mapValue.fields);
      } else if (firebaseArr[i].arrayValue != undefined) {
        array[i] = getFirebaseArray(firebaseArr[i].arrayValue.values);
      } else if (firebaseArr[i].stringValue != undefined) {
        array[i] = firebaseArr[i].stringValue;
      } else if (firebaseArr[i].integerValue != undefined) {
        array[i] = firebaseArr[i].integerValue;
      } else if (firebaseArr[i].doubleValue != undefined) {
        array[i] = firebaseArr[i].doubleValue;
      } else if (firebaseArr[i].booleanValue != undefined) {
        array[i] = firebaseArr[i].booleanValue;
      }
    }
    return array;
  }

  function getFirebaseObject(firebaseObj) {
    var keys = Object.keys(firebaseObj);
    if (keys.length == 1) {
      if (keys[0] == "mapValue") {
        return getFirebaseObject(firebaseObj.mapValue.fields);
      } else if (keys[0] == "arrayValue") {
        return getFirebaseArray(firebaseObj.arrayValue);
      }
    }
    var fields = {};
    for (let key = 0; key < keys.length; key++) {
      if (firebaseObj[keys[key]].mapValue != undefined) {
        fields[keys[key]] = getFirebaseObject(
          firebaseObj[keys[key]].mapValue.fields
        );
      } else if (firebaseObj[keys[key]].arrayValue != undefined) {
        fields[keys[key]] = getFirebaseArray(
          firebaseObj[keys[key]].arrayValue.values
        );
      } else if (firebaseObj[keys[key]].stringValue != undefined) {
        fields[keys[key]] = firebaseObj[keys[key]].stringValue;
      } else if (firebaseObj[keys[key]].integerValue != undefined) {
        fields[keys[key]] = parseInt(firebaseObj[keys[key]].integerValue);
      } else if (firebaseObj[keys[key]].doubleValue != undefined) {
        fields[keys[key]] = parseFloat(firebaseObj[keys[key]].doubleValue);
      } else if (firebaseObj[keys[key]].booleanValue != undefined) {
        fields[keys[key]] = firebaseObj[keys[key]].booleanValue;
      }
    }
    return fields;
  }

  const loadStory = useCallback(async (document, data) => {
    let story = await getFirebaseObject(
      data.docs[document]._document.data.value.mapValue.fields
    );
    let curPanels = story.panels;
    setTitle(story.title);
    setName(story.name);
    setEmail(story.email);
    // console.log(story);
    setApproved(story.approved);
    if (
      story.address !== null &&
      story.address != "" &&
      story.address != undefined
    ) {
      geocodeByAddress(story.address.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          // console.log("Got coords: " + lat + "_" + lng);
          setLongitude(lng);
          setLatitude(lat);
        });
      let loadedAddress = story.address;
      // console.log(loadedAddress);
      setAddress(loadedAddress);
    }
    let background = story.backgroundSelected;
    if (backgroundImageURLs.includes(background) == false) {
      let newBackgroundImageURLs = backgroundImageURLs;
      newBackgroundImageURLs.push(background);
      setBackgroundImageURLs(newBackgroundImageURLs);
      setBackgroundOptionCount(backgroundOptionCount + 1);
    }
    if (backgroundImageURLs[1] != background) {
      for (let bg = 0; bg < backgroundImageURLs.length; bg++) {
        if (backgroundImageURLs[bg] == background) {
          setBackgroundSelected(bg + 1);
        }
      }
    }
    let tagsToToggle = [];
    if (story.tags != undefined) {
      for (let sTag = 0; sTag < story.tags.length; sTag++) {
        for (let tag = 0; tag < tags.length; tag++) {
          if (tags[tag].tlabel == story.tags[sTag]) {
            tagsToToggle[tagsToToggle.length] = tag;
            // console.log(sTag);
          }
        }
      }
    }
    toggleMultipleTags(tagsToToggle);
    setPanelId(curPanels.length);
    setPanels(curPanels);
    setLoaded(true);
  
  });

  function base64EncodeUnicode(str) {
    // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
    // then we convert the percent encodings into raw bytes, and finally we encode it in base64.
    const utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });
    console.log('converting to base64')
    return btoa(utf8Bytes);
  }

  // This function executes after the user finishes creating the form.
  const handleClick = useCallback(() => {
    //// console.log("something was pressed!");

    if (title === "" || name === "" || email === "") {
      // Title, name, or email is not valid
      window.alert("Please check title, email, or name");
    } else if (panels.length === 0) {
      // The story has no panels
      window.alert("Please add panels to the story");
    } else if (anyNullMedia(panels)) {
      // There is a panel missing a required Media item
      window.alert("Please upload missing media");
    } else {
      console.log("submitting");
      if (checkPanelType(panels)) {
        openPopup(base64EncodeUnicode(title + name + email));
      }else{
        window.alert("Please add a description as the first panel and authors and citations to your story at the end of the story ");
      // setSubmitting(true);
    }
  }
  }, [title, name, email, panels]);

  // Blocks navigation if the user has created panels (unless they are submitting)
  // should ask user to save draft also
  useBlocker(
    submitting === false && panels.length > 0,
    "Are you sure you want to leave this page? You have unsaved changes."
  );

  // Routing user to site page
  const navigate = useNavigate();
  useEffect(() => {
    // submits the finished story, need a version of this that submits a draft instead
    async function submitStory() {
      // Generate the unique base64 key to use as path from Title, Name, and Email
      const pathName = storyID;

      // Upload all the Files
      uploadFiles(storage, getFilesFromPanels(panels)).then((urls) => {
        if (urls !== null) {
          // Remove the extra fields from all the Panel objects to upload to Firebase
          const cleanPanels = panels.map((panel) => cleanPanel(panel));

          // Put the File URLs in the panels
          putFileUrlsInPanels(cleanPanels, urls);

          let submittedStory = {
            name: name,
            email: email,
            title: title,
            backgroundSelected: backgroundImageURLs[backgroundSelected - 1],
            panels: cleanPanels,
            approved: false,
            latitude: latitude,
            longitude: longitude,
            tags: getTagsList(),
            approved: approved,
            date: new Date(),
          };

          // console.log(submittedStory);

          if (address != null) {
            submittedStory.address = address;
          }

          // Create a doc for the new story
          setDoc(doc(db, "stories", pathName), submittedStory)
            .then(
              // Navigate back to the stories page
              () => navigate("/Story/" + pathName)
            )
            .catch(
              // If an error occurred
              () => setSubmitting(false)
            );
        } else {
          setSubmitting(false);
        }
      });
    }
    // submit story iff submitting story (button pressed)
    if (submitting) {
      submitStory();
    }
  });

  const [backgroundOptionCount, setBackgroundOptionCount] = useState(6);
  const [backgroundSelected, setBackgroundSelected] = useState(2); // default to background 2
  const [backgroundImageURLs, setBackgroundImageURLs] = useState(null);
  const [panelLength, setpanelLength] = useState(panels.length);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("This is the default popup content.");
  const [popupLink, setPopupLink] = useState("This is the default popup link.");

  useEffect(() => {
    async function fetchImageURLs() {
      // Load all the background image URLs
      const backgroundImageRefs = range(1, 6).map((i) =>
        ref(storage, getBackgroundImagePath(i))
      );
      Promise.all(backgroundImageRefs.map(getDownloadURL)).then((imageURLs) =>
        setBackgroundImageURLs(imageURLs)
      );
    }
    fetchImageURLs();
  }, [storage]);

  const getBackgroundImageURL = useCallback(
    (backgroundNum) => {
      if (backgroundImageURLs === null) {
        return "";
      } else {
        return backgroundImageURLs[backgroundNum - 1];
      }
    },
    [backgroundImageURLs]
  );

  const BackgroundSelectTile = useCallback(
    ({ backgroundNum }) => {
      return (
        <button
          onClick={() => setBackgroundSelected(backgroundNum)}
          style={{ backgroundColor: "transparent", border: "none" }}>
          <img
            src={getBackgroundImageURL(backgroundNum)}
            className={styles.storyBackgroundSelection}
          />
        </button>
      );
    },
    [getBackgroundImageURL, setBackgroundSelected]
  );

  const backgroundTiles = useMemo(
    () =>
      range(1, backgroundOptionCount).map((i) => (
        <Fragment key={`backgroundTile${i}`}>
          <BackgroundSelectTile backgroundNum={i} />
          {i % 2 === 0 && <br />}
        </Fragment>
      )),
    [backgroundOptionCount, BackgroundSelectTile]
  );

  const handleSubmission = () => {
    setPopupOpen(false);
    setSubmitting(true);
    console.log("Submission confirmed");
  };

  const openPopup = (content) => {
    setPopupLink("https://storylab.arizona.edu/Story/"+content);
    setPopupContent(content);
    setPopupOpen(true);
    
  };

  const closePopUp = () => {
    setPopupContent("");
    setPopupOpen(false);
  };

  function AddCustomBackgroundButton() {
    const hiddenFileInput = useRef();
    return (
      <>
        <button
          onClick={() => {
            hiddenFileInput.current.click();
          }}
          style={{
            backgroundColor: "transparent",
            border: "none",
            position: "relative",
          }}>
          {/* TODO: change image to make it clear this is a file upload? */}
          <img src={placeholder} className={styles.storyBackgroundSelection} />
        </button>
        <HiddenFileUpload
          ref={hiddenFileInput}
          imageOnly={true}
          onChange={(file) => {
            // Create a temporary URL for the file
            const tempUrl = URL.createObjectURL(file);
            const img = new Image();
            img.src = tempUrl;
            img.onload = () => {
              // Check the resolution of the image
              const minWidth = 800; // Minimum width in pixels
              const minHeight = 600; // Minimum height in pixels
              if (img.width >= minWidth && img.height >= minHeight) {
                // If the resolution is sufficient, proceed with the upload
                uploadFile(storage, file).then((url) => {
                  // Add the custom image to the Background URLs list and update the count
                  setBackgroundImageURLs((prevUrls) => [...prevUrls, url]);
                  setBackgroundOptionCount((prevCount) => prevCount + 1);
                });
              } else {
                // If the resolution is too low, alert the user
                alert(`Image resolution is too low. Minimum required is ${minWidth}x${minHeight} pixels.`);
              }
              // Revoke the temporary URL to avoid memory leaks
              URL.revokeObjectURL(tempUrl);
            };
          }}
        />
      </>
    );
  }


  
  function AddPanelButton({ img, panelInt }) {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <img
          src={img}
          className={styles.panelPreviewSelection}
          style={{ order: 1 }}
          onClick={() => generateNewPanel(panelInt)}
          role="button"></img>
      </div>
    );
  }

  const generateNewPanel = useCallback(
    /**
     * Generates a new panel of the given type by pushing its data to the list of panels
     * @param {Int} panelType type of panel to create
     */
    (panelInt) => {
      // Generate the new panel data
      let newPanel;

      // generatepanel called before paneljsx

      // switch works
      // gives NewPanel the data that will be passed for the updatePanel function
      switch (panelInt) {
        case 1:
          newPanel = { Description: "", Media: null };
          break;
        case 2:
          newPanel = { Description: "", Media: null };
          break;
        case 3:
          newPanel = { Description: "", Media: null };
          break;
        case 4:
          newPanel = { Description: "", Media: null };
          break;
        case 5:
          newPanel = { Description: "" };
          break;
        case 6:
          newPanel = { Description: "" };
          break;
        case 7:
          newPanel = { Description: "", Title: "" };
          break;
        case 8:
          newPanel = {
            Images: [],
          };
          break;
          case 9:
            newPanel = {Media: null};
            break;
          case 10:
            newPanel = { Description: "", Media: null };
            break;
          case 11:
            newPanel = {Media: null};
            break;
          case 12:
            newPanel = {Description: ""};
            break;
          case 13:
            newPanel = { Description: "", Media: null };
            break;
          case 14:
            newPanel = {Description: ""};
            break;

        default:
          console.error("Unknown panel type " + panelInt);
      }

      // Add the new panel and advance to the next panel ID
      newPanel.id = panelId; // so the ID is the id of the data object, not the actual panel component

      newPanel.PanelType = panelInt;
      setPanels([...panels, newPanel]);
      // no idea what is gannin on, but it seems that setPanels contains the panel data, not the actual panel components
      setPanelId(panelId + 1);
    },
    [panels, setPanels, panelId, setPanelId]
  );

  const removePanel = useCallback(
    /**
     * Removes a panel from the story by index
     * @param {number} index Index of the panel to remove
     */
    (index) => {
      setPanels(panels.filter((_panel, i) => i !== index));
    },
    [panels, setPanels]
  );

  const updatePanel = useCallback(
    /**
     * Set the panel at the given index to the updated data
     * @param {number} index Index of the panel to update
     * @param {any} panelData New panel data
     */
    async (index, panelData) => {
      const newPanels = panels.map((panel, i) =>
        i === index ? panelData : panel
      );
      setPanels(newPanels);
    },
    [panels, setPanels]
  );

  const swapPanels = useCallback((index, direction) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      let index2;

      if (direction === "up") {
        index2 = index - 1;
      } else {
        index2 = index + 1;
      }

      if (
        index >= 0 &&
        index < newPanels.length &&
        index2 >= 0 &&
        index2 < newPanels.length
      ) {
        [newPanels[index], newPanels[index2]] = [
          newPanels[index2],
          newPanels[index],
        ];
      }

      return newPanels;
    });
  }, []);

  const generatePanelJSX = useCallback(
    /**
     * Generates the component for the panel
     * @param {object} panel Panel data
     * @param {number} index Index of this panel
     * // option looks like it is declared in panel.PanelType
     * @returns React component for the panel
     */

    // fixing this function holds the key to fixing the panels though. no cappywappy
    (panel, index) => {
      // Select the correct panel component
      // Note: this works because all six take the same props

      let PanelComponent;

      PanelComponent = GenericPanel;
      // PanelComponent is where it breaks, check the declaration of the PanelComponent Component
      return (
        <PanelComponent
          key={panel.id} // Use panel.id as the key
          data={panel}
          removePanel={() => removePanel(index)}
          updatePanel={(data) => updatePanel(index, data)}
          moveUp={() => swapPanels(index, "up")}
          moveDown={() => swapPanels(index, "down")}
          panelOption={panel.PanelType}
        />
      );
    },
    [removePanel, updatePanel]
  );

  // if width/height < 9/10, return narrow screen
  // tells the user their screen cannot support the story editor
  // still slightly bugged, not a critical thing, might come back and change later
  if (screenWidth / screenHeight <= 80 / 62 || screenWidth <= 900) {
    return (
      <div className={styles.tooNarrowContainer}>
        <p>Sorry!</p>
        <p>
          The story creator tool is not intended for use on narrow or vertical
          screens, such as phones. Please either resize your window to be wider,
          or switch to a supported platform.
        </p>
      </div>
    );
  }

  // really not sure why type equality is tested for in a string password but ok

  // password protection, returns editor if password correct

  // password protection
  if (!passwordCorrect) {
    return <PasswordProtectedPage setPasswordCorrect={setPasswordCorrect} />;
  } else if (docIndex == -1) {
    return <StoryIDRequest setDocIndex={setDocIndex} />;
  } else {
    return (
      <div
        className={styles.pageContainer}
        style={{
          backgroundImage: `url(${getBackgroundImageURL(backgroundSelected)})`,
        }}>
        <div>
        <Popup isOpen={isPopupOpen} onClose = {closePopUp} confirmedSubmission={handleSubmission} link = {popupLink} content={popupContent} />
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2.5 }}>
          <div className={styles.fullContainer}>
            {/* side panel left */}
            {/* must keep constant orientation */}
            <div className={styles.addPanel}>
              <div className={styles.panelCategoryTitles}>Basics</div>

              <div>
                <input
                  className={styles.storyBasicInput}
                  placeholder="Story title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  className={styles.storyBasicInput}
                  placeholder="Author(s)"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  className={styles.storyBasicInput}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <GooglePlacesAutocomplete
                  placeholder="Enter address"
                  selectProps={{
                    placeholder: "Enter address",
                    address,
                    onChange: setAddress,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        color: "#222222",
                      }),
                      option: (provided) => ({
                        ...provided,
                        color: "#222222",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#222222",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 25,
                        marginTop: 10,
                      }),
                    },
                  }}
                  fetchDetails={true}
                /> */}
                <hr className={styles.panelSelectionPartition}></hr>
                <div className={styles.panelCategoryTitles}>Add Tags</div>
                <div className={styles.tagBox}>
                  <div className={styles.tagForm}>
                    {tags.map((tag_obj, index) => (
                      <div
                        key={index}
                        className={`${styles.Tag} ${
                          tag_obj.tprop ? styles.TagSelected : ""
                        }`}
                        onClick={() => toggleTag(index)}>
                        {tag_obj.tlabel}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <hr className={styles.panelSelectionPartition}></hr>
              {/* Panel Previews on the Side Selection */}
              <div className={styles.panelCategoryTitles}>Add panel(s)</div>
              {/* these panels will need each their own style to maintain an aspect ratio and width */}
              
              {/* <AddPanelButton img={panelSevenImage} panelInt={7} />
              <br /> */}
              <AddPanelButton img={panelFiveImage} panelInt={5} />
              <br />

              

              <AddPanelButton img={panelSixImage} panelInt={6} />
              <br /> 

              <AddPanelButton img={panelFourTeenImage} panelInt={14} />
              <br />

              <AddPanelButton img={panelOneImage} panelInt={1} />
              <br />
              <AddPanelButton img={panelNineImage} panelInt={9} />
              <br />
              <AddPanelButton img={panelTwoImage} panelInt={2} />
              <br />
              <AddPanelButton img={panelThreeImage} panelInt={3} />
              <br />
              <AddPanelButton img={panelFourImage} panelInt={4} />
              <br />
              <AddPanelButton img={panelELImage} panelInt={11} />
              <br />
              <AddPanelButton img={panelThirteenImage} panelInt={13} />
              <br />
              <AddPanelButton img={panelTenImage} panelInt={10} />
              <br />
              <AddPanelButton img={panelTwelveImage} panelInt={12} />
              <br />
              
             

              <hr className={styles.panelSelectionPartition}></hr>
              <div>
                <div className={styles.panelCategoryTitles}>
                  Choose Background
                </div>
                {backgroundTiles}
                <AddCustomBackgroundButton />
              </div>
              <hr className={styles.panelSelectionPartition}></hr>
            </div>

            <div className={styles.panelEditorContainer}>
              <div className={styles.innerDiv}>
                {panels.map(generatePanelJSX)}
                {console.log(panels.length)}
              </div>
            </div>

            <div className={styles.submitStoryContainer}>
              <input
                className={styles.submitButton}
                style={{ animation: "none" }}
                value="Submit"
                type="submit"
                onClick={handleClick}
              />
            </div>
          </div>
        </motion.div>
      </div>
    );
  }
}

export default EditStory;
