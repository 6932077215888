import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.css";

export default function Modal({ children, show, onHide, setLink }) {
  const backdropRef = useRef();

  // A function to handle the submission of the link
  const handleSubmit = () => {
    const linkValue = document.getElementById("link").value; // Assuming the input has an ID of "link"
    setLink(linkValue); // This will only close the modal if the link is valid, as per the logic in UploadMedia
  };

  return (
    <>
      {createPortal(
        show && (
          <div
            ref={backdropRef}
            className={styles.modal}
            onMouseDown={(e) => {
              if (e.target === backdropRef.current) {
                onHide(); // Only close if clicking outside the modal
              }
            }}>
            <div className={styles.modalContainer}>
              <div className={styles.modalBox}>
                <div className={styles.modalContent}>
                  {children}
                  <input id="link" type="text" className={styles.textInput} />
                  <button
                    onClick={handleSubmit}
                    className={styles.submitButton}>
                    Submit Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
        document.getElementById("root")
      )}
    </>
  );
}
