import React from "react";
import MediaQuery from "react-responsive";
import styles from "./SharePage.module.css";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

import { useRef, useState } from "react";
import "./SharePage.module.css";


const SharePage = () => {
  const [formSubmitButtonValue, setFormSubmitButtonValue] = useState("");
  const [formVal, setFormVal] = useState();

  // Sending comment to email using Emailjs, https://www.emailjs.com/docs/examples/reactjs/
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_o4lzh6l",
        "template_sw4mmns",
        form.current,
        "FLK6hreOiEnOUcwRY"
      )
      .then(
        (result) => {
          setFormSubmitButtonValue("Successfully submitted!");
          setFormVal("");

          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };

  return (
    <div className={styles.background}>
        <div className= {styles.sharePage}>
          <form className={styles.commentForm} ref={form} onSubmit={sendEmail}>

            <span className={styles.commentFormHeader}>Share your story!</span>
            <p className={styles.mainText}>
              If you are an individual or organization anywhere in the world
              interested in working with Dr. Gubner’s students to share music,
              health and wellness stories that you think could benefit others,
              please be in touch. We would love to hear from you! Please also
              feel free to contact Dr. Gubner with any other questions or
              comments about this project.
            </p>
            <br />
            <input
              type="text"
              name="from_name"
              value={formVal}
              className={styles.formInput}
              placeholder="Full name"
            />
            <input
              type="email"
              name="user_email"
              value={formVal}
              className={styles.formInput}
              placeholder="Email"
            />
            <textarea
              name="message"
              value={formVal}
              placeholder="Comments or questions"
              className={styles.formTextArea}
            />
            <input
              id="formSubmitButton"
              className={styles.formSubmitButton}
              type="submit"
              value="Submit"
            />
            <span className={styles.formSubmitButtonValue}>
              {formSubmitButtonValue}
            </span>
          
          </form>
        </div>
     
     
    

      {/* Mobile Page
      <MediaQuery maxWidth={700}>
        <div className= {styles.sharePage}>
          <form className={styles.commentForm} ref={form} onSubmit={sendEmail}>
            <div style = {styles.headerImage}>
            <span className={styles.commentFormHeader}>Share your story!</span>
            </div>
            <p className={styles.mainText}>
              If you are an individual or organization anywhere in the world
              interested in working with Dr. Gubner’s students to share music,
              health and wellness stories that you think could benefit others,
              please be in touch. We would love to hear from you! Please also
              feel free to contact Dr. Gubner with any other questions or
              comments about this project.
            </p>
            <br />
            <input
              type="text"
              name="from_name"
              value={formVal}
              className={styles.formInput}
              placeholder="Full name"
            />
            <input
              type="email"
              name="user_email"
              value={formVal}
              className={styles.formInput}
              placeholder="Email"
            />
            <textarea
              name="message"
              value={formVal}
              placeholder="Comments or questions"
              className={styles.formTextArea}
            />
            <input
              id="formSubmitButton"
              className={styles.formSubmitButton}
              type="submit"
              value="Submit"
            />
            <span className={styles.formSubmitButtonValue}>
              {formSubmitButtonValue}
            </span>
          </form>
        </div>
      </MediaQuery> */}
    </div> 
  );
};

export default SharePage;
