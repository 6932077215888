import React from "react";
import background from "../Images/blackpaper-navbar.jpeg"; // Ensure this is the correct path
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";

import logoBW from "../Images/ua_rev_horiz_rgb_white.png"; // Path to the black & white logo
import "./Footer.css";

function Footer(props) {

  const pathName = useLocation().pathname;

  if (pathName === "/") {
    return null;
  }

  return (
    <MDBFooter
      className="text-center"
      style={{
        backgroundColor: '#2c3438',
        paddingTop: "2vh",
        color: "#fff",
        width: "100%", // Ensure footer is full width
        margin: "0", // Remove any margins that could affect the width
        // Remove any padding that could affect the width
      }}>
      <MDBContainer fluid>
        <MDBRow className="g-0">
          <MDBCol md="4" className="mb-4">
            <a href="/">
              <img src={logoBW} alt="University of Arizona Logo" style={{ width: "70%" }} />
            </a>
          </MDBCol>

          <MDBCol md="4" className="mb-4  footerText">
            <h6 className="text-uppercase fw-bold mb-4 footerHeaders">Links</h6>
            <p><a href="https://aiar.arizona.edu/" className="text-reset">Applied Intercultural Arts Research GIDP</a></p>
            <p><a href="https://healthyaging.arizona.edu/" className="text-reset">Innovations In Healthy Aging</a></p>
            <p><a href="https://awe.arizona.edu" className="text-reset">The Awe Collective</a></p>
            <p><a href="https://www.gbhi.org/" className="text-reset">Global Brain Health Institute</a></p>
            <p><a href="/CreateStory" className="text-reset">Story Creator</a></p>
          </MDBCol>

          <MDBCol md="4" className="mb-4 footerText">
            <h6 className="text-uppercase fw-bold mb-4 footerHeaders">Contact</h6>
            <p>
              <a href="mailto:jgubner@arizona.edu" className="text-reset">Dr. Jennie Gubner, PhD</a>
            </p>
            <p>
              <a href = "https://music.arizona.edu" className="text-reset"> University of Arizona School of Music</a>
            </p>
            <p>P.O. Box 210004</p>
            <p>1017 N. Olive Rd.</p>
            <p>Music Bldg, Rm 109</p>
            <p>Tucson, AZ 85721-0004</p> 
            <p>Phone: 521-621-6709</p>


          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className="text-center p-4 footerText" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        © 2023 Copyright:
        <a className="text-reset" href="https://arizona.edu/">
          The Arizona Board of Regents on behalf of The University of Arizona
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
