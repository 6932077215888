import React, { useState } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit'; // Assuming MDBIcon is used for icons
import './SideNav.css'; // Adjusted path to CSS file

const Sidenav = ({ isOpen, toggleSidenav }) => {
    return (
      <>
        {/* Adjusted to use `isOpen` prop for controlling visibility */}
        <nav id="sidenav-1" className={`sidenav ${isOpen ? 'open' : ''}`} data-mdb-hidden={!isOpen}>
        <ul className="navbar-nav mr-auto nav_list">
             <li className= "nav_item">
               <a  className= "nav_link" href="/share">SHARE<span className="sr-only">(current)</span></a>
             </li>
             <li className= " nav_item">
               <a className= "nav_link" href="/about">ABOUT</a>
             </li>
             <li className=  "nav_item">
              <a  className= "nav_link"  href="/stories">STORIES</a>
            </li>
          </ul>
        </nav>
        <button className={`sidebutton ${isOpen ? 'open' : ''}`} data-mdb-hidden={!isOpen} onClick={toggleSidenav}></button>
        {/* You may not need this button in Sidenav component if the toggler is in Navbar */}
        {/* Toggler should be placed in the Navbar component to control the Sidenav */}
      </>
    );
  };
  
  export default Sidenav;