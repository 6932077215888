/** Tracks the names of all the background images in Firebase BackgroundImages/ folder */
const backgroundImageNames = [
  "bg1.png",
  "bg2.png",
  "bg3.png",
  "bg4.png",
  "bg5.png",
  "bg6.png",
];

/**
 * Gets the path to reference in Firebase for the given background image
 * @param {number} backgroundNum 1-based background number
 * @returns Path to the background image in Firebase
 */
export function getBackgroundImagePath(backgroundNum) {
  return "BackgroundImages/" + backgroundImageNames[backgroundNum - 1];
}
