// Yoinked from https://gist.github.com/brunodles/927fd8feaaccdbb9d02b

const YOUTUBE_LINK_REGEX = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;

/**
 * Extracts the YouTube ID from the given link
 * @param {string} link Youtube link
 */
export function extractYoutubeId(link) {
    var m = link.match(YOUTUBE_LINK_REGEX);
    if (m === null) {
        // Could not extract an ID
        return null;
    } else {
        // ID is in group 1
        return m[1];
    }
}