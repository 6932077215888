import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import background from "../Images/homepage-bg2-updated-paperback.png";
import mobilebackground from "../Images/mobile_background-updated-paperback.png";
import { motion } from "framer-motion";
import styles from "./Homepage.module.css";
import MediaQuery from "react-responsive";
import Footer from "../Footer/Footer";

function Homepage() {
  useEffect(() => {
    // Scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MediaQuery minWidth={700}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: "center top",
            width: "100vw",
            height: "100vh",
            display: "flex",
            zIndex: "0",
          }}>
          <a href="/Stories">
            <button className={styles.storyLabButton} value="stories">
              STORIES &nbsp;
              <i
                className={`fa-solid fa-angles-right ${styles.faAnglesRight}`}></i>
            </button>
          </a>
          <Link to="/Share">
            <button className={styles.mapButton} value="share">
              <i
                className={`fa-solid fa-angles-left ${styles.faAnglesLeft}`}></i>
              &nbsp;&nbsp; SHARE
            </button>
          </Link>
          {/* replace map button with "about" */}
          <Link to="/About">
            <button className={styles.mapButtonHome}>
              ABOUT
              <br />
              <i
                className={`fa-solid fa-angles-down ${styles.faAnglesDown}`}></i>
            </button>
          </Link>
        </motion.div>
       
      </MediaQuery>
      <MediaQuery maxWidth={700}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{
            backgroundImage: `url(${mobilebackground})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
            height: "100vh",
            display: "flex",
          }}>
          <a href="/Stories">
            <button className={styles.storyLabButton} value="stories">
              STORIES &nbsp;&nbsp;
              <i
                className={`fa-solid fa-angles-right ${styles.faAnglesRight}`}></i>
            </button>
          </a>
          <Link to="/Share">
            <button className={styles.mapButton} value="share">
              <i
                className={`fa-solid fa-angles-left ${styles.faAnglesLeft}`}></i>
              &nbsp;&nbsp; SHARE
            </button>
          </Link>
          {/* replace map button with "about" */}
          <Link to="/About">
            <button className={styles.mapButtonHome}>
              ABOUT
              <br />
              <i
                className={`fa-solid fa-angles-down ${styles.faAnglesDown}`}></i>
            </button>
          </Link>
        </motion.div>
      </MediaQuery>
    </>
  );
}

export default Homepage;
