import React, { useState, useEffect, useCallback } from "react";
import styles from "./Story.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import YoutubeEmbed from "../UploadUtilities/YoutubeEmbed";
import Vimeo from "@u-wave/react-vimeo";
import ReactSoundCloud from "react-soundcloud-embedded";

export default function GenericPanel({ data, panelOption }) {
  //=========================================================================================
  // components and middlewear code

  // default number of images is 1 per slide

  // hybrid setMedia function to facilitate both one and multi image slides (basically just slide 5)

  // panel background component
  // used universally in all panels as the main container inside which all content resides

  //==============================================================================
  // Switch statement / return

  /**
   * <Rant>
   * While I would have preferred each panel option to be returned as a series of componenets
   * to optimize the modularity of the generic panel, unfortunately this cannot be done.
   * This is due to the limitations of React's useCallBack in a nested component return
   * the textarea cannot be the child of any externally defined component, or returned in
   * its own component, as changing the state of the textarea in this case causes a rippling
   * re-render of the components, which un-focuses the textarea, and as a result it can only be
   * filled one character at a time, which is obviously unacceptable.
   * for this reason, panels 1->4 have to be mostly hardcoded with the exception of the trash and image
   * components, otherwise the textarea is interferred with and unuable.
   *
   * The same thing is true for image classes. I cannot believe it, this is a joke. A joke!
   * </Rant>
   */

  /**
   * Panel switch case, returns JSX for the appropriate panel
   */

  function Media({ media, imageClass }) {
    switch (media.type) {
      case "upload":
        return <img className={imageClass} alt="" src={media.url} />;
      case "youtube":
        return (
          <YoutubeEmbed
            embedId={media.embedId}
            className={imageClass}
            width="60vw"
            height="35vw"
          />
        );
      case "vimeo":
        return (
          <Vimeo
            video={media.embedId}
            width={window.innerWidth * 0.6}
            className={imageClass}
          />
        );
      case "soundcloud":
        return (
          <ReactSoundCloud
            url={media.embedId}
            visual={false}
            width={window.innerWidth * 0.6}
            className={imageClass}
          />
        );
      default:
        console.error("Unknown media type " + media.type);
    }
  }
  // console.log(panelOption);
  switch (panelOption) {
    case 1:
      return (
        <div className={styles.panelContainer}>
          <div
            className={styles.largeImageParent}
            style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Media media={data.Media} imageClass={styles.largeImagePreview} />
            <div className={styles.smallCaption}>{data.Description}</div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className={styles.panelContainer}>
          <div
            className={styles.largeImageParent}
            style={{ display: "flex", alignItems: "center", flexDirection: "column-reverse" }}>
            <Media media={data.Media} imageClass={styles.largeImagePreview} />
            <div className={styles.citationCaption}>{data.Description}</div>
          </div>
        </div>
      );

    case 3:
      return (
        <div className={styles.panelContainer}>
          <div
            className={styles.largeImageParent}
            style={{ display: "flex", alignItems: "center", flexDirection: "row-reverse" }}>
            <Media media={data.Media} imageClass={styles.largeImagePreview} />
            <div className={styles.smallCaption}>{data.Description}</div>
          </div>
        </div>
      );

    case 4:
      // console.log("case 4 - generating panel 4");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.largeImageParent} style={{ display: "flex", alignItems: "center" }}>
            <Media media={data.Media} imageClass={styles.largeImagePreview} />
            <div className={styles.smallCaption}>{data.Description}</div>
          </div>
        </div>
      );

    // just a caption, no media
    case 5:
      // console.log("case 5 - generating panel 5");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.text}>
            <div className={styles.smallCaption_noBackground}>{data.Description}</div>
          </div>
        </div>
      );
      case 14:
        // console.log("case 5 - generating panel 5");
        return (
          <div className={styles.panelContainer}>
            <div className={styles.text}>
              <div className={styles.smallCaption_noBackground}>{data.Description}</div>
            </div>
          </div>
        );
  

    // title/heading
    case 6:
      // console.log("case 6 - generating panel 6");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.text}>
            <div className={styles.heading}>{data.Description}</div>
          </div>
        </div>
      );

    case 7:
      // console.log("case 7 - generating panel 7");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.text}>
            <div className={styles.heading}>{data.Description}</div>
            <div className={styles.smallCaption_noBackground}>{data.Title}</div>
          </div>
        </div>
      );

    /* ---------------------------------------------------------------------------------- */
    /* fifth panel option return - most complex one */
    /* this comment exists to create a visual break to improve readibility of the page */
    /* ---------------------------------------------------------------------------------- */
    case 8:
      // // console.log(data);
      // console.log("case 7 - generating panel 7");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.panel5Parent}>
            <div className={styles.topRow}>
              {/* images for top row */}

              <div className={styles.botRowLeft}>
                <div>
                  <Media media={data.Media} imageClass={styles.topRowImage} />
                </div>
              </div>

              <div className={styles.botRowMid}>
                <div>
                  <Media media={data.Media2} imageClass={styles.topRowImage} />
                </div>
              </div>

              <div className={styles.botRowRight}>
                <div>
                  <Media media={data.Media3} imageClass={styles.topRowImage} />
                </div>
              </div>
            </div>

            <div className={styles.midRow}>
              <div className={styles.midRowLeft}>
                <div>
                  <Media media={data.Media4} imageClass={styles.midRowImage} />
                </div>
              </div>

              <div className={styles.midRowRight}>
                <div>
                  <Media media={data.Media5} imageClass={styles.midRowImage} />
                </div>
              </div>
            </div>

            <div className={styles.botRow}>
              <div className={styles.botRowLeft}>
                <div>
                  <Media media={data.Media6} imageClass={styles.topRowImage} />
                </div>
              </div>

              <div className={styles.botRowMid}>
                <div>
                  <Media media={data.Media7} imageClass={styles.topRowImage} />
                </div>
              </div>

              <div className={styles.botRowRight}>
                <div>
                  <Media media={data.Media8} imageClass={styles.topRowImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );


      case 9:
      // console.log("case 4 - generating panel 4");
      return (
        <div className={styles.panelContainer}>
          <div className={styles.largeImageParent} style={{ display: "flex" , alignItems: "center" }}>
            <Media media={data.Media} imageClass={styles.largeImagePreview} />
          </div>
        </div>
      );

      case 11:
        // console.log("case 4 - generating panel 4");
        return (
          <div className={styles.panelContainer}>
            <div className={styles.largeImageParentNoBackground} style={{ display: "flex" , alignItems: "center"}}>
              <Media media={data.Media} imageClass={styles.largeImagePreview} />
            </div>
          </div>
        );

      case 10:
        // console.log("case 10 - generating panel 10");
        return (
          <div className={styles.panelContainerAuthor}>
            <div className={styles.authorImagParent} style={{ display: "flex",  alignItems: "center"}}>
              <Media media={data.Media} imageClass={styles.authorImage} />
              <div className={styles.authorCaption}>{data.Description}</div>
            </div>
          </div>
        );

      case 13:
          // console.log("case 10 - generating panel 10");
          return (
            <div className={styles.panelContainerFirstAuthor}>
              <div className={styles.authorImagParent} style={{ display: "flex",  alignItems: "center"}}>
                <Media media={data.Media} imageClass={styles.authorImage} />
                <div className={styles.authorCaption}>{data.Description}</div>
              </div>
            </div>
      );
  

      case 12:
          // console.log("case 6 - generating panel 6");
          return (
            <div className={styles.CitationPanelContainer}>
              <div className={styles.text}>
                <div className={styles.citations}>{data.Description}</div>
              </div>
            </div>
          );


    default:
      console.error("Unknown panel type requested");
      break;
  }
}
